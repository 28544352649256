import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'
import { AddTaskForm } from '../forms'
import { copyToClipboard } from '../../../helpers';

export const useTaskDialog = () => {

  const { showModalDialog, hideModalDialog } = useModalDialog()
  const addRef = useRef()
  const editRef = useRef()

  const showAddTaskDialog = (data, onSubmit) => {

    const config = {
          title: "Add Task",
          Content: AddTaskForm, 
          contentProps: {
            mode: "add",
            data: data,
            submitForm: onSubmit,
            ref: addRef
          },
          onSubmit: () => addRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
          submitLabel: "Add",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  const showEditTaskDialog = (data, onSubmit) => {
    const itemCount = data.length

    const config = {
          title: itemCount > 1 ? `Edit ${itemCount} Tasks` : "Edit Task",
          Content: AddTaskForm, 
          contentProps: {
            mode: "edit",
            data: data,
            submitForm: onSubmit,
            ref: editRef
          },
          onSubmit: () => editRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
          submitLabel: "Save",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  const showDeleteTaskDialog = async (data, onSubmit) => {

    const config = {
          Content: () => <div>
                <div>Are you sure you want to proceed with the following changes?</div>
                <ul>
                { data.length > 0 &&
                  <li>{data.length + ' Task' + (data.length > 1 ? 's':'')} and related Job logs will be deleted</li>
                }
                </ul>
              </div>,
          onSubmit: () => onSubmit(data),
          submitLabelLoading: "Deleting..."
        }

    showModalDialog(config)

  }

  const showMessageDialog= (data) => {

    const config = {
      title: "Job Completion Message",
      Content: () => <div className="modal-container-body-text wide">
              <p>{data}</p>
            </div>,
      cancelLabel: false,
      submitLabel: "Close",
      onCancel: false,
      customButtons: [{
        label: "Copy",
        tooltip: "Copy query to clipboard",
        onClick: (ref) => { copyToClipboard(ref)}
      }],
    }

    showModalDialog(config)
  }

  return {
    showAddTaskDialog,
    showEditTaskDialog,
    showDeleteTaskDialog,
    showMessageDialog
  }
}
