import React, { forwardRef } from 'react';
import { keyCode } from '../helpers';
import { Icon } from './';
import { useTooltip } from '../hooks'


const _Button = ({name, value, onClick, disabled=false, className, tooltip, buttonStyle, icon, loading}, ref) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        !disabled && onClick();
        break;

      default:
        break;
    }
  }

  const renderIcon = (icon) => {
  	return React.isValidElement(icon) ? icon :  <Icon name={icon} tooltip={tooltip} />
  }

	return (
		<div 
			className={
				"CustomButton" + 
				(className ? " " + className : "" ) + 
				(disabled ? " disabled" : "" ) + 
				(buttonStyle ? " " + buttonStyle : "" ) +
				(loading ? " loading" : "" )
			}
		 	tabIndex={ 0 }
		 	onKeyDown={handleKeyDown}
		 	ref={ref}
		 	onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
      onMouseLeave={ () => tooltip && hideTooltip() }
		 	name={ name }
     	onClick={ !disabled ? onClick : undefined }
		>		
		
		<span>{icon && renderIcon(icon) }{value}</span>
		</div>
	)
}

export const Button = forwardRef(_Button);