export const keyCode = {
	SPACE: 32,
	ENTER: 13,
	DOWN_ARROW: 40,
	UP_ARROW: 38,
	LEFT_ARROW: 37,
	RIGHT_ARROW: 39,
	ESCAPE: 27,
	TAB: 9,
	A: 65,
	F: 70
}