import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'
import { AddUserForm } from '../forms'

export const useUserDialog = () => {

  const { showModalDialog, hideModalDialog } = useModalDialog()
  const addRef = useRef()
  const editRef = useRef()

  const showAddUserDialog = (data, onSubmit) => {

    const config = {
      title: "Add User",
      Content: AddUserForm, 
      contentProps: {
        mode: "add",
        data: data,
        submitForm: onSubmit,
        ref: addRef
      },
      onSubmit: () => addRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
      submitLabel: "Add",
      submitLabelLoading: "Saving..."
    }

    showModalDialog(config)
  }

  const showEditUserDialog = (data, onSubmit) => {
    const itemCount = data.length

    const config = {
      title: itemCount > 1 ? `Edit ${itemCount} Users` : "Edit User",
      Content: AddUserForm, 
      contentProps: {
        mode: "edit",
        data: data,
        submitForm: onSubmit,
        ref: editRef
      },
      onSubmit: () => editRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
      submitLabel: "Save",
      submitLabelLoading: "Saving..."
    }

    showModalDialog(config)
  }

  const showDeleteUserDialog = async (data, onSubmit, onCheckDelete) => {
    showModalDialog({loading: true})

    const checkDelete = await onCheckDelete({ users: data })

    if (checkDelete) { 
      const config = {
        Content: () => <div>
                <div>Are you sure you want to proceed with the following changes?</div>
                <ul>
                { checkDelete.result.user_delete_count > 0 &&
                  <li>{checkDelete.result.user_delete_count + ' User' + (checkDelete.result.user_delete_count > 1 ? 's':'')} will be deleted</li>
                }
                { checkDelete.result.user_disable_count > 0 &&
                  <li>{checkDelete.result.user_disable_count + ' User' + (checkDelete.result.user_disable_count > 1 ? 's still own items and will be disabled':' still owns items and will be disabled')}</li> 
                }
                </ul>
              </div>,
        onSubmit: () => onSubmit(data),
        submitLabelLoading: "Deleting..."
      }

      showModalDialog(config)
    } else {
      hideModalDialog()
    }
  }

  return {
    showAddUserDialog,
    showEditUserDialog,
    showDeleteUserDialog
  }
}
