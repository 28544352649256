import './Questionnaire.css';
import React, { useState, useEffect, useRef } from 'react';

import { useTooltip, useMessage } from '../../../../hooks'
import { Checkbox, Button } from '../../../../components'
import { Question } from './components'
import { renderText } from './helpers'

export const Questionnaire = ({ questionnaire, questions, formData, onChange, onSubmit, onCancel, props, loading, sending }) => {

  const { showTooltip, hideTooltip } = useTooltip()
  const { showMessage } = useMessage()

  const [hasMandatoryQuestions, setHasMandatoryQuestions] = useState(false)
  const [unansweredMandatoryQuestions, setUnansweredMandatoryQuestions] = useState(false)

  const acceptConditionsRef = useRef()

  useEffect( () => {
    let response = false
    for (let i=0; i<questions.length; i++) {
      if (questions[i].question_is_mandatory) {
        response = true
        break
      }
    }
    setHasMandatoryQuestions(response)
  }, [questions])

  useEffect( () => {
    if (questionnaire) {
      let mandatoryQuestions = {}
      questions.forEach(q => {
        if (q.question_is_mandatory && !formData[q.question_id]) {
          mandatoryQuestions[q.question_id] = "notEmpty"
        }
      })

      if (questionnaire.has_accept_conditions && !formData.accept_conditions && !formData.respondent_is_internal) {
        mandatoryQuestions['accept_conditions'] = "notEmpty"
      }
      setUnansweredMandatoryQuestions(mandatoryQuestions)
    }
  }, [questionnaire, formData])

  const renderTitle = (title, props) => {

    if (!title) return null

    return <div className="questionnaire-title">{ renderText(title, props) }</div>

  }

  const renderDescription = (description, props) => {

    if (!description) return null

    return <div className="questionnaire-description">{ renderText(description, props) }</div>

  }

  const checkConditions = () => {
    onChange({name: 'accept_conditions', value: !formData.accept_conditions})
  }

  const renderAcceptConditions = (str, props) => {

    if (!str) return null

    return (
      <div className={"questionnaire-accept-conditions-container" + (sending ? " disabled" : "")}> 
          <Checkbox 
            ref={acceptConditionsRef}
            name="accept_conditions" 
            checked={formData.accept_conditions} 
            onChange={onChange} 
            disabled={sending}
          />
        <div className="questionnaire-accept-conditions" onClick={() => !sending && checkConditions()}>
        { str + " *" }
        </div>
      </div>
    )

  }

  const hasUnansweredMandatoryQuestions = Object.keys(unansweredMandatoryQuestions).length>0
  let questionNumber = 0
  return (
    <div className="Questionnaire">

      { !loading &&
        <React.Fragment>
          { renderTitle(questionnaire.questionnaire_title, props) }

          { onCancel && <div className="close-button" onClick={ onCancel }></div> }

          { renderDescription(questionnaire.questionnaire_description, props) }

          { questions && questions.map( (question, key) => {
              if (question.question_type_code !== 'section') {
                questionNumber++
              }
              return <Question 
                key={key}
                number={questionNumber} 
                showNumbers={questionnaire.show_question_numbers}
                question={question}
                formData={formData}
                onChange={onChange}
                props={props}
                disabled={sending}
              />
            })
          }
          { questionnaire.has_accept_conditions && !formData.respondent_is_internal &&
            renderAcceptConditions(questionnaire.questionnaire_accept_conditions, props)
          }
        
          { onSubmit &&
            <div className="questionnaire-footer">
              { hasUnansweredMandatoryQuestions && 
                <div className="questionnaire-small-text">* You must answer all mandatory questions before you can send your feedback</div>
              }
              <Button className="main-button button" value={sending ? "Sending..." : "Send"} onClick={onSubmit} disabled={hasUnansweredMandatoryQuestions || sending} loading={sending} />

            </div>
          }
        </React.Fragment>
      }
      { loading &&
        <div className="questionnaire-loading">Loading Questionnaire...</div>
      }

    </div>
  )
};


