import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'
import { AddUserGroupForm } from '../forms'

export const useUserGroupDialog = () => {

  const { showModalDialog, renderCheckDeleteBody } = useModalDialog()
  const addRef = useRef()
  const editRef = useRef()

  const showAddUserGroupDialog = (data, onSubmit) => {

    const config = {
          title: "Add User Group",
          Content: AddUserGroupForm, 
          contentProps: {
            mode: "add",
            data: data,
            submitForm: onSubmit,
            ref: addRef
          },
          onSubmit: () => addRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
          submitLabel: "Add",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  const showEditUserGroupDialog = (data, onSubmit) => {
    const itemCount = data.length

    const config = {
          title: itemCount > 1 ? `Edit ${itemCount} User Groups` : "Edit User Group",
          Content: AddUserGroupForm,
          contentProps: {
            mode: "edit",
            data: data,
            submitForm: onSubmit,
            ref: editRef
          },
          onSubmit: () => editRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
          submitLabel: "Save",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  const showDeleteUserGroupDialog = async (data, onSubmit, onCheckDelete) => {
    showModalDialog({loading: true})

    const checkDelete = await onCheckDelete({ user_groups: data })

    const config = {
          Content: () => renderCheckDeleteBody(checkDelete),
          onSubmit: () => onSubmit(data),
          submitLabelLoading: "Deleting..."
        }

    showModalDialog(config)
  }

  const showRemoveUserDialog = async (data, onSubmit) => {

    const config = {
          title: "You are about to remove users from a user group",
          Content: () => <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' User' + (data.length > 1 ? 's':'')} will be removed from this User Group</li>
                  }
                  </ul>
                </div>,
          onSubmit: () => onSubmit(data),
          submitLabelLoading: "Removing..."
        }

    showModalDialog(config)
  }

  return {
    showAddUserGroupDialog,
    showEditUserGroupDialog,
    showDeleteUserGroupDialog,
    showRemoveUserDialog
  }
}
