import React, {useRef, useContext, useCallback } from 'react';
import { copyToClipboard } from '../helpers';

import {ModalContext} from "../context/ModalContext";

const objectMap = {
  task_count: 'Task',
  user_count: 'User',
  user_group_count: 'User Group',
}

export const useModalDialog = () => {

  let {
    modalDialog, 
    setModalDialog,
    loadingModalDialog, 
    setLoadingModalDialog,
    show, 
    setShow
  } = useContext(ModalContext);

  const refContainerBody = useRef()

  const renderCheckDeleteBody = (checkDelete) => {
    return  <div>
              <div>Are you sure you want to delete the following items?</div>
              <ul>
              { Object.keys(checkDelete.result).map( (key, index) => {
                    return checkDelete.result[key] > 0  
                            ? <li key={key}>{checkDelete.result[key] + ' ' + objectMap[key] + (checkDelete.result[key] > 1 ? 's':'')}</li> 
                            : null
                })
              }
              </ul>
            </div>
  }

  const showModalDialog = useCallback( 
    ({title, Content, contentProps, submitLabel, submitLabelLoading, cancelLabel, onSubmit, onCancel, customButtons, loading}) => {

      let config = {
            Content,
            contentProps,
            title: title || "You are about to delete some stuff",
            cancelLabel: cancelLabel !== undefined ? cancelLabel : "Cancel",
            submitLabel: submitLabel !== undefined ? submitLabel : "OK",
            submitLabelLoading: submitLabelLoading ? submitLabelLoading : submitLabel || "OK",
            onSubmit: (data) => {onSubmit !== undefined ? onSubmit(data) : hideModalDialog()},
            onCancel: onCancel !== undefined ? onCancel : hideModalDialog,
            customButtons: customButtons
      }

      setLoadingModalDialog(prev => loading || false)
      setShow(prev => true)
      setModalDialog({...config})

    }, [])

  const hideModalDialog = useCallback(
    (props) => {
      setShow(prev => false)
      setModalDialog({})
    },
    [setShow]
  );
  

  return { 
    showModalDialog, 
    hideModalDialog, 
    show, 
    loadingModalDialog, 
    setLoadingModalDialog,
    modalDialog,
    setModalDialog,
    renderCheckDeleteBody
  }
};