import React, { useRef, useState } from 'react';
import AriaModal from 'react-aria-modal';
import { SkeletonLoader } from './'
import { createPortal } from 'react-dom'
import { useModalDialog } from '../hooks'

const withModalContent = (WrappedComponent, props, loadingModalDialog) => {

  // Use a HOC to add loading state. 
  // Putting this in the Context or ModalDialog component causes the modal "body" component to lose state when the loading state changes

  return () => {
    const [loading, setLoading] = useState(false)

    const bodyRef = useRef()

    let {title, contentProps, submitLabel, submitLabelLoading, cancelLabel, onSubmit, onCancel, customButtons} = props || {}

    return (
      <div className="ModalDialog" tabIndex={loadingModalDialog ? "0" : "-1"}>
        <div className="modal-container">

          <div className="modal-container-header">
            {title}
          </div>

          <div className="modal-container-body" ref={bodyRef}>
            {
              (WrappedComponent && <WrappedComponent {...contentProps} loading={loading} setLoading={setLoading} />) || <React.Fragment><SkeletonLoader /><ul><SkeletonLoader width={150}/></ul></React.Fragment>
            }
          </div>

          <div className="modal-container-footer">
            { customButtons &&

              customButtons.map( (button, index) => {
                return (
                  <button 
                    tabIndex={0} 
                    key={index}
                    onClick={ () => button.onClick(bodyRef) }
                    className="footer-item button"
                    disabled={loadingModalDialog || loading}
                    title={button.tooltip}
                  >
                    <span>{button.label}</span>
                  </button>
                )
              })

            }
            { submitLabel &&
              <button 
                tabIndex={0} 
                onClick={ onSubmit } 
                className={"footer-item button main-button" + (loading ? ' loading' : '')}
                disabled={loadingModalDialog || loading}
              >
                <span>{loading ? submitLabelLoading : submitLabel}</span>
              </button>
            }
            { cancelLabel &&
              <button 
                tabIndex={0} 
                onClick={ onCancel } 
                className="footer-item button"
                disabled={loadingModalDialog || loading}
              >
                <span>{cancelLabel}</span>
              </button>
            }
          </div>

        </div>
      </div>
    )
  }
}

export const ModalDialog = () => {

  const { show, modalDialog, loadingModalDialog } = useModalDialog()

  //const [sending, setSending] = useState(false)
  //const loading = loadingModalDialog
  

  let {title, Content} = modalDialog || {}

  //const portalRef = document.getElementById("modal")

  const getApplicationNode = () => {
    return document.getElementById('root');
  };

  const Modal = AriaModal.renderTo('#modal')

  const Component = withModalContent(Content, modalDialog, loadingModalDialog)

  return show && 

    <Modal
      titleText={title}
      verticallyCenter={false}
      focusDialog={true}
      getApplicationNode={getApplicationNode}
      underlayClass={'modal-underlay'}
    >

      <Component />
    
    </Modal>
  
};
