import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useNotification } from '../hooks';
import { apps } from '../apps'

export const Home = () => {
  
  const { notifications, updateNotifications } = useNotification()

  return (
    <div className={"Home"}>


        <div className="app-grid">

          {
            Object.entries(apps).map(([key, app]) => {
              return (
                <div className="app-grid-container" key={key}>
                <Link  to={ "/" + app.name }>

                    <div className="app-icon">
                    {app.AppIcon &&
                      <app.AppIcon />
                    }
                    { notifications && notifications[app.name].total > 0 &&
                      <div className="app-notification">{notifications[app.name].total}</div>
                    }
                    </div>
                    <div className="app-name">
                      {app.displayName}
                    </div>

                </Link>
                </div>
              )
            })
          }
        </div>


    </div>
  );
}