import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { 
  userGroupService,
  userGroupMemberService 
} from '../services';
import { userService } from '../../../services';
import { formatData, Role, authMethod } from '../../../helpers';
import { 
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  Select, 
  SideDialog,  
  SkeletonLoader,
  Tabs } from '../../../components';
import { useModalDialog, useQueryParams, useGlobalState, useSearch, useMessage } from '../../../hooks';
import { useUserGroupDialog, useUserDialog } from '../hooks';


export const UserGroup = () => {
  
  const appName = useOutletContext()

  const { setHistory } = useQueryParams()

  const [userGroup, setUserGroup] = useState([]);
  const [Users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [morphButtonState, setMorphButtonState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showEditUserGroupDialog, showDeleteUserGroupDialog, showRemoveUserDialog } = useUserGroupDialog()
  const { showEditUserDialog } = useUserDialog()

 
  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage } = useSearch({showMessage, setLoading: setLoadingMoreOptions, objectName: 'user', pagination: true})

  const { user_group_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    
    fetchItems()
    fetchMembers()

  }, [user_group_id]);

  const fetchItems = async () => {
    
    userGroupService.getById(user_group_id)
      .then(res => {

        setUserGroup(res.user_groups[0]);
        setLoading(false)
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const fetchMembers = async () => {
    
    userGroupService.getMembers(user_group_id)
      .then(res => {

        setUsers(res.users);
        setLoadingMembers(false);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const updateMember = async event => {
    let { value } = ""

    value = event.value
    const userGroupId = parseInt(user_group_id)

    const existingUser = Users.find(x => x.user_id === value)
    const user_group_members = [{ user_id: value, user_group_id: userGroupId, user_group_member_id: existingUser?.user_group_member_id }]

    if (existingUser) {
      await removeMember(user_group_members)
    } else {
      await addMember(user_group_members)
    }

  }
  const addMember = async data => {
    //let { name, value } = ""

    //name = event.name
    //value = event.value
    //const userGroupId = parseInt(user_group_id)

    //const user_group_members = { user_group_members: [{ user_id: value, user_group_id: userGroupId }] }


    await userGroupMemberService.create({user_group_members: data})
      .then(async res => { 
        
        fetchSearchResults(undefined, false)
        fetchMembers()

        showMessage(res.message)

      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const editUserGroup = async data => {
    const userGroups = {user_groups: (Array.isArray(data) ? data : [data])}

    await userGroupService.update(userGroups)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      })
  }

  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}    

    await userService.update(users)
      .then(async res => { 

        fetchMembers()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      })
  }

  const deleteUserGroup = async (data) => {    

    await userGroupService.destroy({user_groups: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate('/' + appName + '/user_groups')

      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const checkDeleteUserGroup = async (items) => {    
    return userGroupService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const removeMember = async (data) => {    

    await userGroupMemberService.destroy({user_group_members: data})
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchMembers()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleInputChange = value => {

    setSearchTerm(value)

    if (value.length > 1) { // Only trigger search query for search terms with more than 1 character
      fetchSearchResults(value, false, 1)
    }
  }

  const loadMoreOptions = () => {
   loadNextPage()
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }
  
  const morphButtonToggle = () => {
    setMorphButtonState(!morphButtonState)
    clearSelectedItems()
  }

  const requestResults = searchResults.map(result => {

    return {
            value: result.searchable.user_id, 
            label: result.searchable.user_fullname,  
            secondaryLabel: result.searchable.user_username,
            tooltip: `${result.searchable.user_fullname} (${result.searchable.user_username})\n${result.searchable.user_title ? result.searchable.user_title : ""}`
          }
  })

  const morphButton = () => {
    return (
      <div className={ "morph-button-wrapper " + ((morphButtonState) ? "show" : "") }> 
        <div className="morph-button-content">
          <form className="form" onSubmit={e => e.preventDefault() }>
            <Select 
              name="user_id"
              value={ Users.map(x => {return {value: x.user_id, label: x.user_fullname}}) }
              options={ requestResults }
              onInputChange={ handleInputChange }
              loadMoreOptions={ loadMoreOptions }
              loading={ loadingMoreOptions }
              resultCount={ searchResultsCount && searchResultsCount.user }
              searchTerm={ searchTerm }
              clearSearchResults={ clearSearchResults }
              placeholder="Search Users to add..."
              isMulti={true}
              isClearable={true}
              onChange={ updateMember } 
              onBlur={ morphButtonToggle }
              setFocus={ morphButtonState }
            />
        
          </form>
        </div>
        <button type="button" className="morph-button-toggle main-toolbar-item button main-button" onClick={ morphButtonToggle }><span>ADD +</span></button>
      </div>
      )
  }

  const data = loading ? [] : userGroup

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="USER_GROUP"
          title={data.user_group_name}
          loading={loading}
          >

            <div className="display-linebreak">
            { !loading 
              ? (data.user_group_description === "") ? <span className="no-result-text">No description available</span> : data.user_group_description
              : <SkeletonLoader width="10vw" count={2} />
            }
            </div>

            <h3>{ !loading ? "Email" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_group_email === null) ? "-" : <a className="link" href={"mailto:"+data.user_group_email}>{data.user_group_email}</a>
              : <SkeletonLoader width="10vw" />
            }
            </div>
            
            <h3>{ !loading ? "Default User Role" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_group_default_role_name === null) ? "-" : data.user_group_default_role_name
              : <SkeletonLoader width="10vw" />
            }
            </div>

            <h3>{ !loading ? "User Group Type" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_group_type === null) ? "-" : authMethod[data.user_group_type]
              : <SkeletonLoader width="10vw" />
            }
            </div>

            { data.user_auth_method !== 'local' &&
              <React.Fragment>
                <h3>{ !loading ? "User Group Source Id" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_group_source_id === null) ? "-" : data.user_group_source_id
                  : <SkeletonLoader width="10vw" />
                }
                </div>   
              </React.Fragment>    
            }

            <h3>{ !loading ? "Added" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? formatData(data.created_timestamp, 'datetime')
              : <SkeletonLoader width="10vw" />
            }
            </div>           

          </ObjectDetails>
            
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item main-button button" onClick={ () => showEditUserGroupDialog([data], editUserGroup) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showDeleteUserGroupDialog([data], deleteUserGroup, checkDeleteUserGroup) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          disableTabsWithoutResults={ true }
        >
          <div label="Members" tabId="members" resultCount={loadingMembers || Users.length === 0 ? undefined : Users.length}>

            <DataTable
              columns={[
                {id: 'user_photo', name: '', type: 'user-photo'},
                {id: 'user_fullname', name: 'Name', link: '/'+appName+'/users/:user_id', className:"bold"},
                {id: 'user_username', name: 'Username', className:"prio-2"},  
                {id: 'user_role_name', name: 'Role'},
                {id: 'user_auth_method', name: 'Type', value:'user_auth_method', values: {oidc: {label: 'Azure AD'}, local: {label: 'Local'}}, className:"prio-2"},
                {id: 'user_is_disabled', name: 'Status', type: 'boolean', values: {true: {label: 'Disabled', className: 'pill pill-red'}, false: {label: 'Active', className: 'pill'}}}       
              ]}
              buttons={[ 
                    {customButton: morphButton(), action: 'add', onClick: () => {} },
                    {label: "Edit", action: "edit", onClick: (items) => showEditUserDialog(items || selectedItems, editUser) },
                    {label: "Remove", action: "remove", tooltip: "Remove user from this user group. The User itself will not be deleted.", onClick: (items) => showRemoveUserDialog(items || selectedItems, removeMember) }
                  ]}
              data={Users}
              idColumn='user_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingMembers}
              editable={editable}
              filterable={true}
              filterObjects={['user_fullname', 'user_username', 'user_email', 'user_role_name']}
            />
          </div>

        </Tabs>
      </div>


    </div>
  ) 
}
