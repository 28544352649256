import React from 'react'
import { App } from './views'
import { AppIcon } from './components';
import { routesConfig } from './routesConfig'

const displayName = "Contacts"
const name = displayName.toLowerCase().replace(' ', '_')

export const appDefinition = {
	displayName: displayName,
	name: name,
	Component: () => <App appName={name}/>,
	AppIcon: AppIcon, 
	routes: routesConfig(name, displayName)
}