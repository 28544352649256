import { App } from './views'
import { routesConfig } from './routesConfig'
import { AppIcon } from './components';
import { inviteService, questionnaireResponseService } from './services';
import { displayName, appName } from './appConfig'

const getNotificationCount = async () => {
	const promises = [
		inviteService.getUnansweredInviteCount(),
		questionnaireResponseService.getNewFeedbackCount()
	]

	const data = await Promise.all(promises)

	const requests = parseInt(data[0].unanswered_invite_count)
	const analyze = parseInt(data[1].new_feedback_count)
	return {
		total: requests + analyze,
		requests: requests,
		analyze: analyze
	}
}

export const appDefinition = {
	displayName: displayName,
	name: appName,
	Component: () => <App appName={appName}/>,
	AppIcon: AppIcon, 
	notificationCount: getNotificationCount,
	routes: routesConfig(appName, displayName)
}