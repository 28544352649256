import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'

export const useSettingDialog = () => {

  const { showModalDialog, hideModalDialog } = useModalDialog()

  const showEditSettingDialog = (onSubmit) => {

    const config = {
          title: "Saving settings will trigger a reboot",
          Content: () => <div className="modal-container-body-text">
                  <p>WARNING!</p>
                  <p>Saving settings will reboot the backend API service. Katalogue will be unresponsible for all users until the reboot is finished. This normally takes a couple of seconds.</p>
                  <p>If the backend server fails to reboot due to corrupt setting values, update those directly in the public.setting table in the Katalogue repository database and reboot the backend service manually.</p>
                </div>,
          onSubmit: onSubmit,
          submitLabel: "Save",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  return {
    showEditSettingDialog
  }
}
