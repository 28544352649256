import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Icon, Button } from '../components'
import { useGlobalState } from '../hooks'

export const NotFound = () => {

  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  const navigate = useNavigate()  

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/")
    }
  }, [])

  const goBack = () => {
    navigate("/")
  }

  return (
    <div className="Home columns">
      <div className="center-column column">
          <div className="page-not-found">
            <div className="page-not-found-icon"><Icon name="not_found"/></div>
            <div className="page-not-found-text">Meh!</div>
            <div className="page-not-found-smalltext">This page does not exist</div>
            <Button value="Go to start page" className="button" onClick={goBack} />
          </div>
      </div>
    </div>
  );
}