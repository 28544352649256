import React, { useState, useEffect, forwardRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { taskTypeService } from '../services';
import { Checkbox, Select, formatSelectOptions, Input } from '../../../components';
import { getBatchEditValue, TaskType } from '../../../helpers'
import { useGlobalState, useMessage } from '../../../hooks';

const _AddTaskForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 

	const initialFormState = {
		task_id: null, 
		task_name: '',
		task_description: '', 
		task_type_id: null, 
		task_priority: 100,
		task_is_disabled: false
	}

	const initialBatchEditItems = {
		task_priority: false,
		task_is_disabled: false
	}

	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [taskTypes, setTaskTypes] = useState([]);
	const [taskType, setTaskType] = useState([]);
	const [settings, setSettings] = useGlobalState('settings')

  const { showMessage } = useMessage()

	const fetchTaskTypes = async () => {
	    taskTypeService.getAll()
	      .then(res => { 

	      	const result = settings.OIDC_IS_ENABLED ? res.task_types : res.task_types.filter(x => x.task_type_code !== TaskType.adsync)
	      	setTaskTypes(result);  
	      	
	      	if (data.length > 0) {
	      		setTaskType( res.task_types.find(x => x.task_type_id === data[0].task_type_id) )
	      	}   	
	      })
	      .catch(err => {showMessage(err, 'error')});
	  }

	useEffect( () => {
		// Get form data
    	fetchTaskTypes();
  	}, []);

	useEffect(() => {
		setBatchEdit(false)
	    if (data.length === 1) {

			setFormData({ 
				task_id: data[0].task_id, 
				task_name: data[0].task_name || '',
				task_description: data[0].task_description || '', 
				task_type_id: data[0].task_type_id,
				task_priority: data[0].task_priority || '',
				task_is_disabled: data[0].task_is_disabled
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				task_priority: getBatchEditValue(data, 'task_priority'),
				task_is_disabled: getBatchEditValue(data, 'task_is_disabled')
			})
		} else {
			resetForm()		
		}

		if (data.length > 0) {
			setTaskType( taskTypes.find(x => x.task_type_id === data[0].task_type_id))
		}

	}, [mode, data]);
  
	const handleInputChange = (event, formPart) => {
	  let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

    if (name === 'task_type_id') { setTaskType( taskTypes.find(x => x.task_type_id === value) ) }	
		 
		switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {

				return {task_id: item.task_id, 
								task_name: item.task_name,
								task_description: item.task_description, 
								task_type_id: item.task_type_id,
								task_priority: item.task_priority,
								datasource_id: item.datasource_id,
								task_is_disabled: item.task_is_disabled, 
								...tmp
							}
			})

		} else {
			dataToSubmit = {...formData, 
				datasource_id: taskType.task_type_code === TaskType.dssync ? formData.datasource_id : initialFormState["datasource_id"]
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.catch(err => {
			throw err
		})	 	    
		.finally(res => setLoading(false))
		    
	}

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
	      			return {...initialFormState }
	      			} ) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				{ !batchEdit &&
				<div className="form-block vertical">
				      <Input type="text" 
				      	name="task_name" 
				      	label="Name"
				      	value={formData.task_name} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
					<TextareaAutosize
		      	name="task_description" 
		      	value={formData.task_description} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		    </div>
		  	}

{ batchEdit 
							? <label>
									<Checkbox 
										value={batchEditItems.batch_task_is_disabled}
										name="batch_task_is_disabled"
										label="Edit Disabled"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: null
						}
								     <div className="form-block horizontal">
		      <Checkbox 
		      	name='task_is_disabled' 
		      	checked={formData.task_is_disabled } 
		      	onChange={handleInputChange}
		      	label='Disabled'
		      	disabled={loading || (batchEdit && !batchEditItems.batch_task_is_disabled)}
		      />
	      </div>

		  	<div className="form-block vertical">
			  	{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_task_priority}
									name="batch_task_priority"
									label="Edit Priority"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Priority</label>
					}
					<Input type="text" 
			      	name="task_priority" 
			      	value={formData.task_priority === 'multiple' ? '' : formData.task_priority} 
			      	onChange={handleInputChange}
			      	placeholder={formData.task_priority === 'multiple' ? '< Multiple >' : ""}
			      	disabled={loading || (batchEdit && !batchEditItems.batch_task_priority)}
			      />
		    </div>

				{ !batchEdit &&
		    <div className="form-block vertical">
					<label>Task Type</label>
					<Select 
            name="task_type_id"
            value={formData.task_type_id}
            options={ formatSelectOptions({options: taskTypes, optionValue: "task_type_id", optionLabel: "task_type_name", tooltip:"task_type_description"}) }
            onChange={handleInputChange} 
            disabled={loading}
          />
		    </div>
		  	}	

	    </form>
	  </div>
  )
}

export const AddTaskForm = forwardRef(_AddTaskForm)
