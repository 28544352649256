import React from 'react';
import { Outlet } from 'react-router-dom'
import { Role } from '../../../helpers';
import { Icon, NavBar } from '../../../components';



export const App = ({appName}) => {

  const links =[
    {
      type: "link",
      relativePath: "",
      label: "Home",
      icon: <Icon name="home" />,
      roles: [Role.admin]
    },
    {
      type: "section",
      label: "Manage",
      roles: [Role.admin],
      links: [
        {
          type: "link",
          relativePath: "users",
          label: "Users",
          icon: <Icon name="users" />,
          roles: [Role.admin]
        },
        {
          type: "link",
          relativePath: "user_groups",
          label: "User Groups",
          icon: <Icon name="user_groups" />,
          roles: [Role.admin]
        },
        {
          type: "link",
          relativePath: "tasks",
          label: "Tasks",
          icon: <Icon name="tasks" />,
          roles: [Role.admin]
        },
        {
          type: "link",
          relativePath: "settings",
          label: "Settings",
          icon: <Icon name="settings" />,
          roles: [Role.admin]
        },
      ]
    }
  ]

  return (
    <div className="app-container">

        <NavBar links={links} appName={appName} />

        <div className="app-content">
          <Outlet context={appName} />
        </div>

    </div>
  );
}