import React from 'react';

export const FullscreenMessage = ({message}) => {

  return message && message.type === "error" && 
    <div className="FullscreenMessage">
      <div className={"error-msg"}>
        <div className="error-title">{ message.title }</div>
        <div className="error-details">{ message.details }</div>
      </div>
    </div>
};
