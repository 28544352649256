import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Asset, Role, copyToClipboard, formatData, subjects } from '../../../helpers';
import { Icon, ObjectDetails, SkeletonLoader, Button, Checkbox } from '../../../components';
import { useModalDialog, useQueryParams, useGlobalState, useMessage } from '../../../hooks';
import { 
  userService 
} from '../../../services';
import { useProfileDialog } from '../hooks';
import kayentaLogo from '../assets/kayenta_logo_black.png'
import iconPhone from '../assets/icon_phone.png'
import iconMail from '../assets/icon_mail.png'
import iconLinkedin from '../assets/icon_linkedin.png'
import iconAvatar from '../assets/icon_avatar.png'


export const Home = () => {

  const { queryParams, setHistory } = useQueryParams()

  const [loggedInUser] = useGlobalState('loggedInUser');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false)
  const [includePhoto, setIncludePhoto] = useState(true)

  const { showMessage } = useMessage()
  const { showModalDialog, hideModalDialog, setLoadingModalDialog } = useModalDialog()
  const { showEditProfileDialog } = useProfileDialog()

  const navigate = useNavigate()

  const signatureRef = useRef()

  useEffect(() => {   
    if (copied) {
      setTimeout(() => {setCopied(false)}, 500)
    }
  }, [copied]);

  const updateData = () => {

  }
  const editProfile = async user => {
    const users = {users: [user]}

    setLoadingModalDialog(true)

    await userService.updateProfile(users)
      .then(async res => { 
        showMessage(res.message)
        hideModalDialog()
      })
      .catch(err => { 
        showMessage(JSON.stringify(err), 'error') 
        throw err
      })
      .finally(res => {
        setLoadingModalDialog(false)
      })
  }

  const changePassword = async item => {    
    hideModalDialog()
    navigate('/change_password')
  }

  const handleEditProfile = () => {
    if (loggedInUser.user_auth_method === "local") {
      showEditProfileDialog([loggedInUser], editProfile)
    } else {
      window.open(`https://eur.delve.office.com/?u=${loggedInUser.user_source_id}&v=editprofile`, '_blank')
    }
  }

  const handleInputChange = (event) => {
    let { name, value } = ""
    // Handle standard form inputs
    if (event.target !== undefined) {
      // If input element is a checkbox, we cannot use "value"
      if (event.target.type === "checkbox") { value = event.target.checked }
      setIncludePhoto(value)
    }
  }

  const copySignature = () => {
    setCopied(true)
    copyToClipboard(signatureRef, "formatting")
  }

  const data = loading ? [] : loggedInUser

  const editableAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  return (
    <div className={"Home"}>
      <div className="columns narrow-margin profile">
        <div className="column profile-card">
          <ObjectDetails
            type="USER"
            title={data.user_fullname}
            secondaryTitle={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
            photo={data.user_photo}
            subtitleText={ data && !loading &&
              data.user_username
            }
            loading={loading}
            >

            <div className="contact-info-container">
              <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_email) ? <React.Fragment><Icon name="email" /><div className="error-notification"></div>-</React.Fragment> : <a className="link" href={"mailto:"+data.user_email}><Icon name="email" />{data.user_email}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>

              <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_phone) ? <React.Fragment><Icon name="phone" /><div className="error-notification"></div>-</React.Fragment> : <a className="link" href={"tel:"+data.user_phone}><Icon name="phone" />{formatData(data.user_phone, 'phone')}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>

              <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_linkedin_vanity_name) ? <React.Fragment><Icon name="linkedin" /><div className="error-notification"></div>-</React.Fragment> : <a className="link" target="_blank" href={"https://www.linkedin.com/in/" + data.user_linkedin_vanity_name}><Icon name="linkedin" />{"https://www.linkedin.com/in/" + data.user_linkedin_vanity_name}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>
            </div>

          </ObjectDetails>
          
          { !loading &&
          <div className="main-toolbar">
              <button type="button" className="main-toolbar-item main-button button" onClick={ handleEditProfile }><span>EDIT</span></button>
            { data.user_auth_method === 'local' && 
              <button type="button" className="main-toolbar-item button" onClick={ changePassword }><span>CHANGE PASSWORD</span></button>
            }
          </div>
          }

        </div>

        <div className="column profile-content">

          <div className="large-nav-links">

            <div className="large-nav-links-title">YOUR EMAIL SIGNATURE</div>

            <div className="email-signature-container">

            <div className="email-signature" ref={signatureRef}>
              <table 
                border="0"
                width="300"
                style={{
                  margin: "0",
                  padding: "0",
                  borderCollapse: "collapse"
              }}>
                <tbody>
                <tr style={{margin: "0", padding: "0"}}>
                  { includePhoto &&
                    <td rowSpan="4"  style={{paddingRight: "15px", backgroundColor: "#fff"}} width="90" height="90">
                      <img src={ loggedInUser.user_photo ? "data:image/jpeg;base64," + loggedInUser.user_photo : iconAvatar } width="90" height="90" alt={loggedInUser.user_fullname} />
                    </td>
                  }
                  <td 
                    colSpan="3" 
                    style={{
                      fontFamily: "'Arial', sans-serif",
                      fontWeight: "bold",
                      fontSize: "11pt",
                      color: "#221E20"
                  }}>
                    {loggedInUser.user_fullname}
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td colSpan="3" style={{margin: "0", padding: "0"}}>
                    <span
                      style={{
                        fontFamily: "'Arial', sans-serif",
                        color: "#221E20",
                        textDecoration: "none",
                        fontSize: "10pt"
                    }}>
                        {formatData(data.user_phone, 'phone')}
                    </span>
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td colSpan="3" style={{margin: "0", padding: "0"}}>
                    <a href={"mailto:" + loggedInUser.user_email}
                       style={{
                        fontFamily: "'Arial', sans-serif",
                        color: "#221E20",
                        textDecoration: "none",
                        fontSize: "9pt"
                    }}>
                      {loggedInUser.user_email}
                    </a>
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td style={{paddingTop: "8px", paddingBottom: "0", backgroundColor: "#fff"}} width="32">
                    { loggedInUser.user_phone &&
                      <a href={"tel:" + loggedInUser.user_phone} style={{textDecoration: "none"}}>
                        <img src={iconPhone} width="25" height="25" alt="phone" />
                      </a>
                    }
                  </td>
                  <td style={{paddingTop: "8px", paddingBottom: "0", backgroundColor: "#fff"}} width="32">
                    <a href={"mailto:" + loggedInUser.user_email} style={{textDecoration: "none"}}>
                      <img src={iconMail} width="25" height="25" alt="mail" />
                    </a>
                  </td>
                  <td style={{paddingTop: "8px", paddingBottom: "0", backgroundColor: "#fff"}} width={includePhoto ? "191" : "296"}>
                  { loggedInUser.user_linkedin_vanity_name &&
                    <a href={"https://www.linkedin.com/in/" + loggedInUser.user_linkedin_vanity_name} style={{textDecoration: "none"}}>
                      <img src={iconLinkedin} width="25" height="25" alt="linkedin" />
                    </a>
                  }
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td colSpan="4" style={{paddingTop: (includePhoto ? "25px" : "15px"), paddingLeft: "5px", backgroundColor: "#fff"}}>
                    <a href="http://www.kayenta.se"  style={{textDecoration: "none"}}>
                      <img src={kayentaLogo} width="75" height="17" alt="kayenta-logo" />
                    </a>
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td 
                    colSpan="4"
                    style={{
                      paddingLeft: "5px",
                      paddingTop: "5px",
                      backgroundColor: "#fff"
                  }}>
                    <a 
                      href="https://goo.gl/maps/AoKJfRg7Um9GLRCR7"
                      style={{
                        fontFamily: "'Arial', sans-serif",
                        fontSize: "9pt",
                        color: "#9E9592",
                        textDecoration: "none"
                    }}>
                        Kayenta Consulting AB<br />
                        Wallingatan 32, 111 24 Stockholm
                    </a>
                  </td>
                </tr>
                <tr style={{margin: "0", padding: "0"}}>
                  <td 
                    colSpan="4"
                    style={{
                      paddingLeft: "5px",
                      paddingTop: "5px",
                      backgroundColor: "#fff"
                  }}>

                    <a 
                      href="http://www.kayenta.se"
                      style={{
                        fontFamily: "'Arial', sans-serif",
                        fontSize: "9pt",
                        color: "#9E9592",
                        paddingTop: "5px",
                        display: "block",
                        textDecoration: "none"
                    }}>
                        www.kayenta.se
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div className="email-signature-include-photo-button">
              <Checkbox 
                label="Include Photo"
                checked={includePhoto}
                name="include_photo"
                onChange={handleInputChange}
              />
            </div>

            <div className="email-signature-instructions">
              <Button 
                value={copied ? "Copied!" : "Copy"}
                className={"button main-button email-signature-copy-button"} 
                onClick={copySignature}
                disabled={loading || copied} />

              <ol>
                <li>Click the copy button above to copy the signature</li>
                <li>Paste the signature in your email client's signature editor</li>
              </ol>

            </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

