import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { SkeletonLoader, Button, Input } from '../../../components';
import { useMessage, useGlobalState } from '../../../hooks';
import { formatData } from '../../../helpers';
import { 
  userService 
} from '../../../services';


export const Home = () => {

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [users, setUsers] = useState()
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(false);

  const { showMessage } = useMessage()

  useEffect( () => {
    fetchUsers()
  }, [])

  const fetchUsers = async () => {
    setLoading(true)
    userService.getAll()
      .then(res => { 

        setUsers(res.users); 

      })
      .catch(err => {showMessage(err, 'error')})
      .finally(setLoading(false));
  }

  const handleInputChange = (event) => {
    let { name, value } = ""
  
    // Handle standard form inputs
    if (event.target !== undefined) {
      name = event.target.name
      value = event.target.value

      // If input element is a checkbox, we cannot use "value"
      if (event.target.type === "checkbox") { value = event.target.checked }

    // Handle custom form inputs
    } else {
      name = event.name
      value = event.value
    }

    switch(name) {
      case 'search':
        setSearchTerm(prev => {return value})
        break
    }
  }

  const renderName = (str) => {
    const words = str.split(' ')

    const firstName = words[0]
    words.shift() // remove first word
    const lastName = words.length > 0 ? words.join(' ') : ""

    return (<React.Fragment><span className="strong">{firstName}</span>&nbsp;{lastName}</React.Fragment>)
  }
  const data = loading ? [] : users

  return (
    <div className={"Contacts"}>
      
      <div className="contact-list-container">
        <div className="contact-list-header">
          <div className="contact-list-search">
            <Input
              type="text"
              name="search"
              className="contact-list-search-input"
              autoComplete="new-password"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              isNullable={true}
            />

          </div>
        </div>

        <div className="contact-list">
        { !loading && users && users.filter(x => (
            x.user_id !== loggedInUser.user_id && 
            x.user_auth_method === 'oidc' && 
            (searchTerm && searchTerm.length > 1 ? x.user_fullname.toLowerCase().includes(searchTerm.toLowerCase()) : true)))
          .map( (user, index) => (

            <Link className={"contact-list-item"} key={index} to={"" + user.user_id}>
              <div className="contact-list-item-photo">
                { formatData(user.user_photo, 'user-photo') }
              </div>
              <div className="contact-list-item-body">
                <div className="contact-list-item-title">{renderName(user.user_fullname)}</div>
              </div>
            </Link>
          )) 
        }
        { loading &&

            <div className={"contact-list-item"}>
              <div className="contact-list-item-photo">
                <SkeletonLoader width="30px" height="30px" />
              </div>
              <div className="contact-list-item-body">
                <div className="contact-list-item-title"><SkeletonLoader width="200px"/></div>
              </div>
            </div>
        }
        </div>
      </div>
    </div>
  );
}

