import React, { useState, useEffect, useRef } from 'react';
import { Link, useOutletContext, useNavigate, useLocation } from 'react-router-dom'

import { Asset } from '../../../helpers';
import { SkeletonLoader, Button } from '../../../components';
import { useMessage, useModalDialog, useNotification } from '../../../hooks';
import { IconFeedback } from '../components';
import { inviteService, questionnaireResponseService } from '../services';
import { useFeedbackDialog } from '../hooks';

export const Home = () => {

  const appName = useOutletContext()
  const navigate = useNavigate()
  const location = useLocation()

  const { showMessage } = useMessage()
  const { hideModalDialog, setLoadingModalDialog } = useModalDialog()
  const { showAddInviteDialog, showNewFeedbackDialog } = useFeedbackDialog()
  const { notifications } = useNotification()

  const addInvite = async data => {
    const invites = {invites: (Array.isArray(data) ? data : [data])}

    await inviteService.create(invites)
      .then(res => {
        hideModalDialog()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const newFeedback = props => {
    hideModalDialog()
    navigate(`${location.pathname}/requests/give?user_id=${props.user_id}&questionnaire_id=${props.questionnaire_id}`)
  }

  return (
    <div className={"Home"}>


        <div className="large-nav-links">

          <div className="large-nav-link-container">

            <div className="large-nav-link-block">
              <Button 
                value="get feedback" 
                className="button"
                icon={<IconFeedback name="invite_feedback"/>}
                onClick={() => {showAddInviteDialog([], addInvite)}} />

              <Button 
                value="Give feedback" 
                className="button"
                icon={<IconFeedback name="give_feedback"/>}
                onClick={() => {showNewFeedbackDialog([], newFeedback)}} />
            </div>

            <Link to={"/" + appName + "/requests"} className="large-nav-link">
              <div className="large-nav-link-icon-container">
                <div className="large-nav-link-icon">
                  <IconFeedback name="give_feedback"/>
                </div>
                <div className="large-nav-link-subtitle">
                  Feedback Requests
                </div>
              </div>
              <div className="large-nav-link-number">
                { notifications && notifications[appName].requests }
              </div>
            </Link>

            <Link to={"/" + appName + "/analyze"} className="large-nav-link">
              <div className="large-nav-link-icon-container">
                <div className="large-nav-link-icon">
                  <IconFeedback name="analyze_feedback"/>
                </div>
                <div className="large-nav-link-subtitle">
                  New Feedback
                </div>
              </div>
              <div className="large-nav-link-number">
                { notifications && notifications[appName].analyze }
              </div>
            </Link>

          </div>
        </div>

    </div>
  );
}