import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { feedbackService } from '../services';
import { userService } from '../../../services';
import { Checkbox, formatSelectOptions, Input, Select, Button } from '../../../components';
import { authMethod, getBatchEditValue, formatData } from '../../../helpers'
import { useGlobalState, useDebounce, useMessage } from '../../../hooks'
import { Questionnaire, IconFeedback } from '../components'
import { inviteService, questionService, questionnaireService } from '../services'


const _GiveFeedbackForm = ({ mode, data, submitForm, setNotFound }, ref) => { 
	
	const [settings] = useGlobalState('settings');
	const [loading, setLoading] = useState(true)
	const [sending, setSending] = useState(false)
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState({});
	const [invite, setInvite] = useState();
	const [users, setUsers] = useState();
	const [questionnaire, setQuestionnaire] = useState()
  const [questions, setQuestions] = useState([])
	const [searchTerm, setSearchTerm] = useState('');

  const { showMessage } = useMessage()
  const navigate = useNavigate()

	useEffect( () => {
		// Get items
		if (data && data.token) {
			fetchInvite(data.token)
		} else if (data && data.user_id && data.questionnaire_id) {
			fetchItems(data.questionnaire_id, data.user_id)
			setFormData(prev => ({
				...prev, 
				user_id: data.user_id,
				questionnaire_id: data.questionnaire_id,
				respondent_is_internal: true
			}))
		} else {
  		fetchItems()
		}
	}, []);

	useEffect( () => {
		// Get items
		if (invite) {
			setFormData(prev => ({
					...prev,
	      	user_id: invite.user_id,
	      	invite_id: invite.invite_id,
	      	questionnaire_id: invite.questionnaire_id,
	      	respondent_is_internal: invite.respondent_is_internal
	      })
			)
		}

	}, [invite]);

	const fetchInvite = async token => {
		try {
			const invite = await inviteService.getByToken(token)
				.then(res => {return res.invites[0]})
				.catch(err => {throw err})

			if (invite) {
				setInvite(invite)

				const questionnaireId = invite.questionnaire_id

				const promises = [
		      fetchQuestionnaire(questionnaireId), 
		      fetchQuestions(questionnaireId)
		    ]

		    await Promise.all(promises).then(data => {
		      setQuestionnaire(data[0])
		      setQuestions(data[1])
		    })
		    .catch(err => {throw err})
		  } else {
		  	setNotFound(true)
		  }
	  } catch (err) {
			showMessage(err, 'error')
	  } finally {
			setLoading(false)
	  }
	}

	const fetchUsers = async (userId) => {
		return new Promise( async (resolve, reject) => {
			let user
			try {
				if (userId) {
					user = await userService.getById(userId)
						.then(res => { 
			      	return res.users; 
			      })
			      .catch(err => {throw err})
				} else {
			    user = await userService.getAll()
			      .then(res => { 
			      	return res.users.filter(x => x.user_id !== loggedInUser.user_id); 
			      })
			      .catch(err => {throw err})
			  }

			  resolve(user)
		  } catch (err) {
				reject(err)
			}
		})
  }

  const fetchItems = async (questionnaireId, userId) => {
    setLoading(true)

    const promises = [
      fetchQuestionnaire(questionnaireId), 
      fetchQuestions(questionnaireId),
      fetchUsers(userId)
    ]

    await Promise.all(promises).then(data => {
      setQuestionnaire(data[0])
      setQuestions(data[1])
      setUsers(data[2])
    })
    .catch(err => showMessage(err, 'error'))

    setLoading(false)
  }

  const fetchQuestionnaire = (questionnaireId) => {
    return new Promise( async (resolve, reject) => {
      try {

        const res =  questionnaireId ? await questionnaireService.getById(questionnaireId) : await questionnaireService.getDefault()
        resolve(res.questionnaires[0])

      } catch (err) {
        reject(err)
      };
    })
  }

  const fetchQuestions = (questionnaireId) => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = questionnaireId ? await questionService.getByQuestionnaireId(questionnaireId) : await questionService.getByDefaultQuestionnaire()
        resolve(res.questions)

      } catch (err) {
        reject(err)
      };
    })
  }
  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  if (name === 'user_id') {
	  	setFormData({ ...formData, [name]: value, respondent_is_internal: true })
	  } else {
	  	setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		setSending(true)

		let response = {
			questionnaire_response: {
				user_id: formData.user_id,
				questionnaire_id: formData.questionnaire_id || questionnaire.questionnaire_id,
				respondent_is_internal: formData.user_id ? true : false,
				invite_id: formData.invite_id
			},
			responses: Object.entries(formData).filter(([key, value]) => !isNaN(key)).map( ([key, value]) => { 
				return {
					question_id: key,
					response_value: value
				}
			})
		}

		// submit form
		submitForm(response)
		.catch(err => {
		})
		.finally(res => setSending(false))
		    
	}

  const cancelForm = () => {
  	navigate(-1)
  }

  const resetForm = () => {
  	setFormData({}) 
  }

  const formatQuestionnaireProps = () => {
  	let response = invite ? {user_fullname: invite.user_fullname} : users && users.find(x => x.user_id === parseInt(formData.user_id))

  	if(response) {
  		response.user_first_name = response.user_fullname.split(' ')[0]
  	}

  	return response
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
			{ !formData.user_id && !loading && users &&
				<div className="form-block vertical">
					<Select
	          name="user_id"
	          label="Select a colleague to begin"
	          value={formData.user_id}
	          options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname"  }) }
	          onChange={handleInputChange} 
	          disabled={loading}
	        />
	      </div>
	    }		

	    { ((formData.user_id && questionnaire && questions) || loading) && 
				<Questionnaire 
					questionnaire={questionnaire}
					questions={questions}
					formData={formData}
					onChange={handleInputChange}
					onSubmit={onSubmit}
					props={formatQuestionnaireProps()}
					loading={loading}
					sending={sending}
					onCancel={cancelForm}
				/>
			}
	    </form>
	  </div>
  )
}

export const GiveFeedbackForm = forwardRef(_GiveFeedbackForm)


