import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from './';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useGlobalState, useTooltip } from  '../hooks';

export const Breadcrumbs = ({routes}) => {

  const breadcrumbs = useBreadcrumbs(routes)
  const location = useLocation()

  const [currentBusinessTerm, setCurrentBusinessTerm] = useGlobalState('currentBusinessTerm')
  const [currentDataset, setCurrentDataset] = useGlobalState('currentDataset')
  const [currentDatasetGroup, setCurrentDatasetGroup] = useGlobalState('currentDatasetGroup')
  const [currentDatasource, setCurrentDatasource] = useGlobalState('currentDatasource')
  const [currentField, setCurrentField] = useGlobalState('currentField')
  const [currentFieldDescription, setCurrentFieldDescription] = useGlobalState('currentFieldDescription')
  const [currentGlossary, setCurrentGlossary] = useGlobalState('currentGlossary')
  const [currentSystem, setCurrentSystem] = useGlobalState('currentSystem')
  const [currentTask, setCurrentTask] = useGlobalState('currentTask')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [currentUserGroup, setCurrentUserGroup] = useGlobalState('currentUserGroup')

  const { showTooltip, hideTooltip } = useTooltip()

  const renderBreadcrumbs = () => {
    const toRender = []

    breadcrumbs.forEach(({ match, breadcrumb, tooltip }, key) => {
      if (breadcrumbs.length > 1 && key > 0 && breadcrumbs.length > (key+1)) {
        toRender.push(
          <Link 
            key={key} 
            className={"breadcrumb-item" + (key < breadcrumbs.length ? " link": "") + (key === 1 ? " first-in-app": "")} 
            to={ match.pathname }
            onMouseEnter={() => showTooltip({title: breadcrumb})}
            onMouseLeave={hideTooltip}>
            <span className="breadcrumb-item">
              { breadcrumb }
            </span>
          </Link>
        )
      } else if (breadcrumbs.length > 1 && key > 0 && breadcrumbs.length <= (key+1)) {
        toRender.push(
          <span key={key} className={"breadcrumb-item" + (key === 1 ? " first-in-app": "")}>
            { breadcrumb }
          </span>
        )
      }
      if (breadcrumbs.length > (key+1) && key > 1) {
        toRender.push(<span className="divider" key={"divider_"+key.toString()}>></span>)
      }
    })
    if (toRender.length > 1) {
      return [toRender[0], <div className="app-breadcrumbs" key="key">{toRender.slice(1, toRender.length)}</div>]
    } else {
      return toRender
    }
  }

  return (
    <div className="Breadcrumbs">

      { breadcrumbs.length === 1 &&
        <span className="breadcrumb-item-container">
          <Icon className="breadcrumb-item" name='kayenta' tooltip='Home' />
          {/*<span className="default-breadcrumb breadcrumb-item">
            Apps
          </span>*/}
        </span>
      }
      { breadcrumbs.length > 1 &&
        <React.Fragment>
        <span className="breadcrumb-item-container apps-link">
          <Link className="breadcrumb-item link" to="/">
            <Icon className="breadcrumb-item" name='apps' tooltip='Home' />
          </Link>
        </span>
        <span className="breadcrumb-item-container">
          <div className="vertical-spacer"></div>
        </span>
        </React.Fragment>
      }
      {
        <span className="breadcrumb-item-container">
        { renderBreadcrumbs() }
        </span>
      }
    </div>
  );
};
