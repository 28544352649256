import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Role } from '../helpers'
import { authenticationService, settingService } from '../services'
import { PrivateRoute } from '../middleware'
import { 
  Breadcrumbs, 
  Icon, 
  Message,
  ModalDialog, 
  Photo, 
  NavLink,
  Tooltip,
} from '../components'
import { routesConfig } from '../routesConfig'
import { Login, LoadApp, NotFound } from './'
import { ChangePassword } from './'
import packageJson from '../../package.json';
import { useGlobalState, useMessage, useModalDialog, useNotification, useInterval } from '../hooks';
import { apps } from '../apps'

export const App = () => {
  return (
    <React.Fragment>
      <_App />
      <Tooltip />
      <ModalDialog />     
      <Message />
    </React.Fragment>
  )
}

const _App = () => {

  const [loading, setLoading] = useState(true)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [csrfToken, setCsrfToken] = useGlobalState('csrfToken')
  const [settings, setSettings] = useGlobalState('settings')

  const { showMessage, hideMessage } = useMessage()
  const { show } = useModalDialog()
  const { updateNotifications } = useNotification()
  const location = useLocation()
  const navigate = useNavigate()

  // Update notifications every 1 minute
  useInterval( () => {
    isLoggedIn && updateNotifications()
  }, 60000)

  useEffect( () => {
    // Initialize app
    initialize()

  }, [])

  const initialize = async () => {

    try {

      await Promise.all([
        // Get settings from backend
        await getSettings()
        ,
        // Get login state
        await getLoginState()
      ])
      .catch(e => { throw e })

      setLoading(false)

    } catch (err) {
      setLoading(false)
    }
  }

  const getSettings = async () => {
    try {
      let settings = await settingService.getAllForFrontend()

      settings = settings.settings
      settings = {
        ...settings,
        authMethods: [
          ...(settings.OIDC_IS_ENABLED  ? ['oidc'] : []),
          ...(settings.LOCAL_IS_ENABLED ? ['local'] : [])
        ]
      }
      setSettings(settings)
    } catch(err) {
      throw err
    }
  }

  const getLoginState = async () => {
    try {
      const loginState = await authenticationService.endLogin(window.location.href, undefined)

      if (loginState.userInfo) {

        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        const state = JSON.parse(loginState.state)

        /// Restore url
        navigate({...state.from, ...state})

      }

      setIsLoggedIn(loginState.isLoggedIn)

      if (loginState.isLoggedIn) {
        updateNotifications()
      }

    } catch(err) {
      throw err
    }
  }

  const login = async ({username, password, state}) => {
    try {

      const loginState = await authenticationService.login(username, password)

      if (loginState.userInfo) {
        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        setIsLoggedIn(loginState.isLoggedIn)
        hideMessage()

        if (loginState.isLoggedIn) {
          updateNotifications()
        }

        // Restore url
        navigate({...state.from, ...state})

      }

    } catch (e) {
      // Errors handled in Login.js
      throw e  
    }
  }

  const logout = async () => {
    try {
      const logout = await authenticationService.logout()

      const tmpUser = loggedInUser
      setIsLoggedIn(false)
      setLoggedInUser({})

      hideMessage()
      
      if (tmpUser.user_auth_method !== 'local') {
        location.href = logout.logoutURL
      }

    } catch(e) {
      showMessage(e, 'error')
    }
  }

  const getAllRoutes = () => {
    let appRoutes = []
    apps && Object.entries(apps).forEach( ([key, app]) => {
      if (app.routes) {
        appRoutes = [...appRoutes, ...app.routes]
      }
    })
    return [...routesConfig, ...appRoutes]

  }

  const getRoutes = () => {
    let appRoutes = []
    apps && Object.entries(apps).forEach( ([key, app]) => {
      if (app.routes) {
        const route = {
          path: "/" + app.name,
          breadcrumb: app.displayName,
          element: app.Component,
          routes: app.routes
        }
        appRoutes = [...appRoutes, route]
      }
    })
    return [...routesConfig, ...appRoutes]

  }

  const renderRoutes = (routesArray) => {
    return routesArray.map(({ path, name, element, roles, routes }, key) => {
      if (routes) {
        return (
          <Route 
            key={path} 
            path={path} 
            element={
              <PrivateRoute 
                roles={ roles }
                element={ element }   
              />
            }
          >
            { renderRoutes(routes) }
          </Route>
        )
      } else {
        return (
          <Route 
            key={path} 
            path={path} 
            element={
              <PrivateRoute 
                roles={ roles }
                element={ element }   
              />
            }
          />
        )
      }
    })
  }

  const breadcrumbs = useBreadcrumbs(getAllRoutes())

  return (

    <div className={'App' + (show ? " modal-dialog-is-shown" : "")}>
      
      <div className="main-container">
        { isLoggedIn && 
          !( location.pathname === "/change_password" || location.pathname === "/login") && 
          !loading &&

        <div className="main-content-header-wrap">
          <div className={"main-content-header" + (location.pathname === "/" ? " home" : "") + (breadcrumbs.length > 3 ? " has-app-breadcrumbs" : "") + (breadcrumbs.length === 3 ? " has-one-app-breadcrumb" : "")} >
            
            <Breadcrumbs routes={getAllRoutes()} />
            
            <div className="menu">

              <div className="menu-item admin-tools">
                <div className="menu-button">
                  <Link to={ "/admin_tools" }><Icon name="admin_tools"/></Link>
                </div>
                <div className="menu-content">
                  <Link className="menu-link" to={ "/admin_tools/users" }><Icon name="users"/><span>Users</span></Link>
                  <Link className="menu-link" to={ "/admin_tools/user_groups" }><Icon name="user_groups"/><span>User Groups</span></Link>
                  <Link className="menu-link" to={ "/admin_tools/tasks" }><Icon name="tasks"/><span>Tasks</span></Link>
                  <Link className="menu-link" to={ "/admin_tools/settings" }><Icon name="settings"/><span>Settings</span></Link>
                </div>
              </div>

              <div className="menu-item">
                <div className="menu-button">
                  <div className="about-icon">i</div>
                </div>
                <div className="menu-content">
                  <div className="about-logo"></div>
                  <div className="about-version">Version {packageJson.version}</div>
                  <div className="about-copyright">©{new Date().getFullYear()} <a href="http://www.kayenta.se" rel="noopener noreferrer" target="_blank">Kayenta Consulting AB</a>.<br/>All rights reserved</div>
                </div>
              </div>

              <div className="menu-item last">
                <Link className="" to={ "/profile" }>
                  <div className="menu-button">
                    <span className="user-name">
                      <Photo
                        size='small'
                        data={ loggedInUser.user_photo }
                        loading={ loading }
                      />
                    </span>
                  </div>
                </Link>
                <div className="menu-content"> 
                  <div className="user-profile">
                  <Photo
                    size='medium'
                    data={ loggedInUser.user_photo }
                    loading={ loading }
                  />
                    <div className="user-profile-info">
                      <div className="user-profile-name">{loggedInUser.user_fullname}</div>
                      {loggedInUser.user_title &&
                        <div className="user-profile-title">{loggedInUser.user_title}</div>
                      }
                      <div className="user-profile-username">{loggedInUser.user_username}</div>
                    </div>
                  </div>
                  <div className={loggedInUser.user_auth_method !== 'local' ? "horizontal" : ""}>
                    <Link className="menu-link" to={ "/profile" }><Icon name="profile"/><span>Profile</span></Link>
                    { loggedInUser.user_auth_method === 'local' && 
                      <Link className="menu-link" to={ "/change_password" }><Icon name="password"/><span>Change Password</span></Link>
                    }
                    <div className="menu-link" onClick={ logout }><Icon name="signout"/><span>Sign Out</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        <div className="main-content">
          { loading 
            ? <LoadApp />
            : 
              <Routes>

                <Route path="/login" element={
                  <Login 
                    login={login} />
                }/>

                <Route path="/change_password" element={
                  <ChangePassword  />
                }/>

                {renderRoutes(getRoutes())}

                <Route path='*' element={<NotFound />}/>

              </Routes>
              
          }
        </div>
      </div>  
    </div>

  )
}