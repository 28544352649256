import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authenticationService } from '../services'
import { Input, Tabs, FullscreenMessage } from '../components'
import { useGlobalState, useMessage, useModalDialog } from '../hooks'

export const Login = ({login}, props) => {

  const initialFormState = {
    username: '', 
    password: ''
  }

  const [formData, setFormData] = useState(initialFormState)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [settings] = useGlobalState('settings');
  const [loading, setLoading] = useState(false)

  const { message, showMessage, hideMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()

  const navigate = useNavigate()
  const location = useLocation()
  
  useEffect(() => {
    
    hideModalDialog()

    // Redirect to start page if user is already logged in
    if (isLoggedIn && loggedInUser && !loggedInUser.password_change_required) { 
      navigate('/')
    }    
  }, []);

  const handleInputChange = (event) => {
    const { value, name } = event.target
  
    hideMessage()
  
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    const { username, password }  = formData

    try {

      setLoading(true)
      const state = location.state || { from: { pathname: "/" } };

      await login({username, password, state})

      setLoading(false)

    } catch (error) {

      setLoading(false)
      showMessage(error, 'error', true)
    }
  }

  const loginAzureAD = async (event) => {
    event.preventDefault()

    try {

      setLoading(true)
      const state = JSON.stringify(location.state || { from: { pathname: "/" } });

      const url = await authenticationService.startLogin(state)

      // Redirect to OAuth endpoint
      window.location.href = url.authorizationRequestUrl
        
    } catch (error) {

      setLoading(false)
      showMessage(error, 'error', true)
    }
  }  
  
  const OIDC = ({key}) => {
    return (
      <div label="Azure AD" tabid="oidc" key={key}>
        <button 
          type="button" 
          className={"button large-button" + (loading ? ' loading' : '')} 
          onClick={loginAzureAD}
          disabled={loading}>
          <span>{loading ? 'Signing In...' : (settings?.authMethods.length > 1 ? 'Sign In' : 'Sign In With Azure AD')}</span>
        </button>
      </div>
    )
  }

  const Local = ({key}) => {
    return (
      <div label="Username/Password" tabid="username" key={key}>
        <form onSubmit={onSubmit}>
          <Input
            type="text"
            name="username"
            className="large"
            label="Username"
            labelAsPlaceholder={true}
            value={formData.username}
            onChange={handleInputChange}
            disabled={loading}
            required={true}
          />
          <Input
            type="password"
            name="password"
            className="large"
            label="Password"
            labelAsPlaceholder={true}
            value={formData.password}
            onChange={handleInputChange}
            disabled={loading}
            required={true}
          />
          <p>&nbsp;</p>
          <button 
            type="submit" 
            className={"button large-button" + (loading ? ' loading' : '')} 
            disabled={loading}>
            <span>{loading ? 'Signing In...' : 'Sign In'}</span>
          </button>
        </form>
      </div>
    )
  }
  const tabs = {
    oidc: OIDC,
    local: Local,
  }

  const authMethods = settings.authMethods ? settings.authMethods.map(x => {
    const tab = Object.entries(tabs).find(([key, Component]) => x === key)
    if (tab) {
      const [key, Component] = tab
      return Component({key: key})
    } 
  }) : []

  return (
    <div className="Login fullscreen">
      <div className="fullscreen-content">
        
        <div className="fullscreen-logo"></div>
        
        { authMethods.length > 1
          ? <Tabs className="slim vw-20">
              {
                authMethods.map(Component => {
                  return Component
                }) 
              }
            </Tabs>
          : <React.Fragment>
              {
                authMethods.map(Component => {
                  return Component
                }) 
              }
            </React.Fragment>
        }

        <FullscreenMessage message={message} />

      </div>
    </div>
  )
}