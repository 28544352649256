import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Spinner} from '../assets/img/logo_loading.svg';
import { FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'

export const LoadApp = () => {

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  const { message, showMessage, hideMessage } = useMessage()

  const navigate = useNavigate()

  useEffect(() => {
    
    // Redirect to start page if user is already logged in
    if (isLoggedIn && loggedInUser && !loggedInUser.password_change_required) { 
      navigate('/')
    }    
  }, []);

  return (
    <div className="LoadApp fullscreen">
      <div className="fullscreen-content">

        <Spinner className="fullscreen-loading-logo" />

        {message && message.type === "error"
          ? <FullscreenMessage message={message} />
          : <div className="fullscreen-loading-text">Loading Apps...</div>
        }
      </div>
    </div>
  )
}