import React, { useState, useEffect, useRef } from 'react';
import { inviteService } from '../services';
import { DataTable } from '../../../components';
import { formatData } from '../../../helpers';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { useFeedbackDialog } from '../hooks';

export const Invite = () => {

  const [invites, setInvites] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { showMessage } = useMessage()
  const { hideModalDialog, modalDialog, showModalDialog } = useModalDialog()
  const { showAddInviteDialog, showDeleteInviteDialog } = useFeedbackDialog()

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    await inviteService.getByUserId(loggedInUser.user_id)
      .then(res => {
        const result = res.invites.map(item => ({
          ...item,
          latest_invite_event_timestamp: formatData(item.latest_invite_event_timestamp, 'datetime').props.children
        }))
        setInvites(result)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
      .finally(res => setLoading(false))
  }
  

  const addInvite = async data => {
    const invites = {invites: (Array.isArray(data) ? data : [data])}

    await inviteService.create(invites)
      .then(res => {
        hideModalDialog()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      })
  }

  const deleteInvite = async data => {    

    await inviteService.destroy({ invites: data })
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const checkDeleteInvite = async (items) => {    
    return inviteService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const copyInviteLink = (items) => {
    const url = `https://feedback.kayenta.se/${items[0].invite_token}`
    navigator.clipboard.writeText(url)
  }

  const sendInviteEmail = async (items) => {

    const selectedInvitations = items || selectedItems

    selectedInvitations.map(async invite => {
      updateSendEmailStatus(invites, setInvites, [{invite_id: invite.invite_id, email_status: 'sending'}])
      updateSendEmailStatus(selectedItems, setSelectedItems, [{invite_id: invite.invite_id, email_status: 'sending'}])
    })

    const data = items.map(item => item.invite_id)

    await inviteService.sendMail({ invite_ids: data })
      .then(async res => { 
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
        return res
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
      .finally(res => {
        data.forEach( inviteId => {
          updateSendEmailStatus(invites, setInvites, [{invite_id: inviteId, email_status: 'none'}])
          updateSendEmailStatus(selectedItems, setSelectedItems, [{invite_id: inviteId, email_status: 'none'}])
        })
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const updateSendEmailStatus = (arr, setNewState, invites) => {
    
    let tmp_arr = arr
    let i = -1
    let tmp = null

    invites.forEach(invite => {
      i = arr.findIndex(x => x.invite_id === invite.invite_id)
      tmp = tmp_arr[i]
      tmp_arr[i] = {
        ...tmp, 
        email_status: invite.email_status
        }
    })
    setNewState([...tmp_arr]); 
  }

  const respondentIsInternalValues = {true: {label: 'Internal'}, false: {label: 'External'}}

  const emailSentValues = {true: {label: 'Yes', className: 'pill pill-green'}, false: {label: 'No', className: 'pill pill-grey'}}

  const statusValues = {
    rejected: {label: 'Rejected', className: 'pill pill-wide pill-red'}, 
    responded: {label: 'Responded', className: 'pill pill-wide pill-green'},
    opened: {label: 'Opened', className: 'pill pill-wide '},
    created: {label: 'Created', className: 'pill pill-wide pill-grey'}
  }

  return (
    <div className="Invite wide-margin">

      <DataTable
        columns={[
          {id: 'respondent_user_photo', name: '', type: 'user-photo', tooltip: 'respondent_name'},
          {id: 'respondent_name', name: 'To', tooltip: 'respondent_email'},  
          {id: 'invite_token', name: 'Invitation Code'},
          {id: 'latest_invite_event_type_name', name: 'Status', type: 'pill', value: 'latest_invite_event_type_code', values: statusValues, tooltip:'latest_invite_event_timestamp'},
          {id: 'email_is_sent', name: 'Email Sent', type:'boolean', values:emailSentValues, className:"prio-2" },
          {id: 'created_timestamp', name: 'Created At', type: 'datetime', className:"prio-2"},
          {id: 'respondent_is_internal', name: 'Respondent Type', type: 'boolean', values: respondentIsInternalValues, className:"prio-2"},  
          {id: 'questionnaire_name', name: 'Questionnaire', className:"prio-2"}
        ]}
        buttons={[ 
          {label: "Add +", action: "add", mainButton: true, onClick: (items) => showAddInviteDialog(items || selectedItems, addInvite) },
          {label: "Send Email", action: "send", disabled: {field: "email_status", value: "sending", label: "sending...", tooltip: "One or more selected tasks are already running" }, onClick: (items) => sendInviteEmail(items || selectedItems) },
          {label: "Copy Invitation Link", action: "copy", onClick: (items) => copyInviteLink(items || selectedItems) },
          {label: "Delete", action: "delete", onClick: (items) => showDeleteInviteDialog(items || selectedItems, deleteInvite, checkDeleteInvite) }
        ]}
        data={invites}
        title="Feedback Invitations"
        idColumn='invite_id'
        primaryColumn='invite_id'
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loading={loading}
        editable= {true}
        filterable={true}
        filterObjects={['respondent_is_internal', 'respondent_name', 'invite_token', 'latest_invite_event_type_name', 'questionnaire_name']}
        filterObjectsValueMap={{respondent_is_internal: respondentIsInternalValues}}
      />

    </div>
  );
}