import React, { useCallback, forwardRef, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { SkeletonLoader } from '../';
import { Checkbox } from '../';
import { DataTableRow, DataTableToolbar, DataTablePagination } from './components';
import { useFilterableData, useSortableData, useQueryParams, useDebounce } from '../../hooks';


export const _DataTable = ({
  data, 
  title, 
  buttons, 
  filters,
  hideToolbar, 
  idColumn, 
  primaryColumn=idColumn.replace("_id", "_name"),
  columns, 
  selectedItems = [], 
  setSelectedItems, 
  editable=true, 
  loading=false, 
  loadingMoreRows=false, 
  filterable=false, 
  header=true, 
  filterObjects, 
  filterObjectsValueMap,
  defaultFilterTerm, 
  defaultSort, 
  className, 
  onMouseEnterRow, 
  onMouseLeaveRow,
  paginated=true,
  pageSize=200,
  currentPage=1
}, 
  ref) => {

  const { queryParams, setHistory } = useQueryParams()
  const { filteredItems, requestFilter, filterTerm } = useFilterableData(data, filterObjects, filterObjectsValueMap, defaultFilterTerm);
  const { items, requestSort, sortConfig } = useSortableData(filteredItems, defaultSort);
  const [page, setPage] = useState(queryParams.tablePage || currentPage)
  const [scroll, setScroll] = useState(queryParams.tableScroll)

  const tmpRef = useRef()
  const wrapperRef = ref || tmpRef

  useEffect(() => {
    const element = wrapperRef.current
    if (element) {

      element.addEventListener('scroll', trackScrolling)
      return () => { 
        element.removeEventListener('scroll', trackScrolling) 
      }
    }
  }, [wrapperRef.current]);

  useLayoutEffect(() => {
    // Restore scroll
    wrapperRef.current.scrollTop=scroll
  }, [wrapperRef.current, items]);


  useEffect(() => {
    setHistory({'tableScroll':  scroll, 'tablePage':  page})
  }, [scroll, page, filterTerm]);

  const trackScrolling = useDebounce( () => { // Debounce to reduce number of calls to setState
    setScroll(wrapperRef.current.scrollTop)
  }, 200).debounce 

  const handleFilter = (value) => {
    // Go to first page and scroll to top
    selectPage(1)

    // De-select any selected items
    if (setSelectedItems) setSelectedItems([])

    // Apply filter
    requestFilter(value)
  }

  const handleFilterFocus = () => {
    // De-select any selected items
    if (setSelectedItems) setSelectedItems([])
  }

  const handleSort = (value) => {
    // Go to first page and scroll to top
    selectPage(1)

    // Apply sort
    requestSort(value)
  }

  const resetScroll = () => {
    wrapperRef.current.scrollTop = 0
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const selectItem = (items) => {

    let array = selectedItems

    items.forEach( item => {
      
      if (item.checked) {
        if (array.findIndex(x => x[idColumn].toString() === item.itemId) === -1) { // add only if not already in array
          setSelectedItems(prevState => [...prevState, ...[data.find(x => x[idColumn].toString() === item.itemId)]] )
        }
      } else {
        setSelectedItems(prevState => prevState.filter(x => x[idColumn].toString() !== item.itemId))
      }    
    })
  }
  
  const handleSelect = useCallback( (event) => {

    const itemId = event.target.name.split("-")[1]
    const checked = event.target.checked

    // Add item to selection array
    selectItem([{ itemId: itemId , checked: checked }])

  }, [items, selectedItems])

  const handleSelectAll = (event) => {

    const checked = event.target.checked

    let array = []
    
    items.forEach(item => {
      let itemId = item[idColumn].toString()

      array.push({ itemId: itemId , checked: checked }) 
    })

    // Add items to selection array
    selectItem(array)
  }

  const itemCount = items.length
  const selectedItemsCount = selectedItems.length

  const rowActionEdit = buttons && buttons.find(x => x.action === 'edit')?.onClick
  const rowActionDelete = buttons && buttons.find(x => x.action === 'delete')?.onClick
  const rowActionRemove = buttons && buttons.find(x => x.action === 'remove')?.onClick
  const rowActionRun = buttons && buttons.find(x => x.action === 'run')

  const rowActionCount = (rowActionEdit ? 1 : 0) + (rowActionDelete ? 1 : 0) + (rowActionRemove ? 1 : 0) + (rowActionRun ? 1 : 0)

  const paginatedItems = paginated ? items.slice((page-1)*pageSize,page*pageSize) : items
  const pageCount = Math.ceil(items.length / pageSize)

  const stepPage = (steps) => {
    if (steps < 0 ) {
      if (page + steps < 1) {
        selectPage(1)
      } else {
        selectPage(page + steps)
      }
    } else {
      if (page + steps > pageCount) {
        selectPage(pageCount)
      } else {
        selectPage(page + steps)
      }
    }
  }

  const selectPage = (pageNum) => {
    resetScroll()
    setPage(pageNum)
  }

  const pages = []
  let i = 0
  while (i < pageCount) {
    pages.push(i+1)
    i++
  }

  let columnCount = editable ? 2 : 0
  columns.forEach( (column) => {
    columnCount = columnCount + (column.type === "chart" ? 2 : 1)
  })


  return (
    <React.Fragment>

    { !hideToolbar &&
    
    <DataTableToolbar
      title={title}
      buttons={buttons}
      filters={filters}
      itemTotalCount={data.length}
      selectedItems={selectedItems}
      selectedItemsCount={selectedItemsCount}
      itemCount={itemCount}
      editable={editable}
      filterable={filterable}
      requestFilter={handleFilter}
      onFilterFocus={handleFilterFocus}
      filterTerm={filterTerm}
      />

    }

    <div className="DataTableWrapper" ref={wrapperRef} >
    <table className={"DataTable" + (className ? " " + className : "")}>
      {header && 
        <thead>
          <tr>
            { editable && 
              <th className="checkbox">
              { items.length > 0  &&
                <Checkbox
                  name={`select-all`}
                  onChange={handleSelectAll}
                  checked={(items.length > 0 && items.length === selectedItems.length) ? true : false}
                />
              }
              </th>
            }
            {columns.map((column) => (

              <th key={column.id}
                onClick={() => (column.type === 'user-photo' || column.type === 'group-photo' || column.type === 'button' ? null : handleSort(column.id))}
                className={
                  (column.type === 'user-photo' || column.type === 'group-photo' ? "no-right-margin" : '') +
                  (column.type === 'user-photo' || column.type === 'group-photo' || column.type === 'button' ? "" : ' sortable ') +
                  getClassNamesFor(column.id) + ' ' + column.className + 
                  ((column.align === undefined) ? ' left' : ' '+ column.align) 
                }
                colSpan={column.type === 'chart' ? 2 : 1 }
              >
                {column.name}

            </th>
            ))}
            
            { editable &&
                <th className={"last-column width-"+ rowActionCount.toString() }></th>
              }
          </tr>
        </thead>
      }
      <tbody>

      {!loading &&

        paginatedItems.map( (item, index) => (

        <DataTableRow
          key={index}
          item={item}
          itemCount={itemCount}
          idColumn={idColumn}
          columns={columns}
          selected={ selectedItems.find(x => { return (x[idColumn] === item[idColumn]); }) }
          editable={editable}
          handleSelect={handleSelect}
          actionEdit={rowActionEdit}
          actionDelete={rowActionDelete}
          actionRemove={rowActionRemove}
          actionRun={rowActionRun}
          onMouseEnterRow={onMouseEnterRow}
          onMouseLeaveRow={onMouseLeaveRow}
        />

        ))
      }
      { (loading || loadingMoreRows) &&  
          <tr>
            { editable && 
              <td></td>
            }
            {columns.map((column, index) => (
              <td key={index} colSpan={column.type === 'chart' ? 2 : 1 }>
              { column.type === 'icon' 
                ? <SkeletonLoader width="1em" />
                : <SkeletonLoader width="5vw" />
              }
              </td>
              
            ))}
            { editable &&
              <td className={"last-column width-"+rowActionCount.toString()}></td>
            }
          </tr>
      }

      </tbody>
    </table>
    {!loading && items.length === 0 ? <div className="empty-text">No assets</div> : null}

    </div>
        {!loading && paginated && pageCount > 1 &&
      <DataTablePagination 
        items={items}
        primaryColumn={primaryColumn}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        stepPage={stepPage}
        selectPage={selectPage}
      />
    }
    </React.Fragment>
  );
};

export const DataTable = forwardRef(_DataTable)