import './Questionnaire.css';
import React, { useState, useEffect, useRef } from 'react';

import { useTooltip, useMessage, useGlobalState } from '../../../../hooks'
import { formatData } from '../../../../helpers'
import { Photo } from '../../../../components'
import { Question } from './components'
import { renderText } from './helpers'

export const QuestionnaireReadOnly = ({ questionnaire, questions, responses, questionnaireResponses, onCancel, props, loading }) => {

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const { showTooltip, hideTooltip } = useTooltip()
  const { showMessage } = useMessage()

  const renderHeader = (data) => {

    if (!data) return null

    if (data.length > 1) {
      const questionnaireResponse = data[0]
      return <div className="questionnaire-response-header">
        <div className="questionnaire-response-header-row"><span className="questionnaire-response-header-label">Feedback to</span><Photo data={questionnaireResponse.user_id === loggedInUser.user_photo ? loggedInUser.user_photo : questionnaireResponse.user_photo} /><strong>{questionnaireResponse.user_id === loggedInUser.user_id ? "You" : questionnaireResponse.user_fullname}</strong></div>
        <div className="questionnaire-response-header-row"><span className="questionnaire-response-header-label">From</span><strong>{data.length}</strong>&nbsp;respondents</div>
      </div>

    } else {
      const questionnaireResponse = data[0]
      return <div className="questionnaire-response-header">
        <div className="questionnaire-response-header-row"><span className="questionnaire-response-header-label">Feedback to</span><Photo data={questionnaireResponse.user_id === loggedInUser.user_photo ? loggedInUser.user_photo : questionnaireResponse.user_photo} /><strong>{questionnaireResponse.user_id === loggedInUser.user_id ? "You" : questionnaireResponse.user_fullname}</strong></div>
        <div className="questionnaire-response-header-row"><span className="questionnaire-response-header-label">From</span><Photo data={questionnaireResponse.respondent_email === loggedInUser.user_photo ? loggedInUser.user_photo : questionnaireResponse.respondent_user_photo} /><strong>{questionnaireResponse.respondent_email === loggedInUser.user_email ? "You" : questionnaireResponse.respondent_name}</strong></div>
        <div className="questionnaire-response-header-row"><span className="questionnaire-response-header-label">Received at</span><strong>{formatData(questionnaireResponse.created_timestamp, "datetime")}</strong></div>
      </div>
    }

  }

  const renderTitle = (title, props) => {

    if (!title) return null

    return <div className="questionnaire-title">{ renderText(title, props) }</div>

  }

  const renderDescription = (description, props) => {

    if (!description) return null

    return <div className="questionnaire-description">{ renderText(description, props) }</div>

  }

  let questionNumber = 0
  return (
    <div className="Questionnaire">

      { !loading &&
        <React.Fragment>

          { renderHeader(questionnaireResponses) }

          { onCancel && <div className="close-button" onClick={ onCancel }></div> }

          { renderTitle(questionnaire.questionnaire_title, props) }

          { renderDescription(questionnaire.questionnaire_description, props) }
          
          { questions && questions.map( (question, key) => {
              if (question.question_type_code !== 'section') {
                questionNumber++
              }
              return (
                <Question 
                  key={key} 
                  number={questionNumber}
                  showNumbers={questionnaire.show_question_numbers}
                  question={question}
                  formData={responses[question.question_id]}
                  questionnaireResponses={questionnaireResponses}
                  props={props}
                  disabled={true}
                  readOnly={true}
                />  
              )
            })
          }
                  
        </React.Fragment>
      }
      { loading &&
        <div className="questionnaire-loading">Loading...</div>
      }

    </div>
  )
};


