import React, { createContext, useState, useMemo } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState()

  const value = useMemo( () => (
    { 
      notifications,
      setNotifications
    }
  ), [
    notifications,
    setNotifications
  ])

  return (
    <NotificationContext.Provider
      value={value}
    >
      {children}
    </NotificationContext.Provider>
  );
}