import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { authMethod, formatData, Role, Asset } from '../../../helpers';
import { 
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  Select,
  SideDialog,  
  SkeletonLoader,
  Tabs, 
  Icon } from '../../../components';
import { useModalDialog, useQueryParams, useGlobalState, useMessage } from '../../../hooks';
import { useUserDialog, useUserGroupDialog } from '../hooks';
import { userGroupService, userGroupMemberService } from '../services';
import { userService } from '../../../services';


export const User = () => {
  
  const appName = useOutletContext()

  const { queryParams, setHistory } = useQueryParams()

  const [user, setUser] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsAll, setUserGroupsAll] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [morphButtonState, setMorphButtonState] = useState(false);
  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object})
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { showMessage } = useMessage(setSelectedItems)
  const { hideModalDialog } = useModalDialog()
  const { showEditUserDialog, showDeleteUserDialog } = useUserDialog()
  const { showEditUserGroupDialog, showRemoveUserDialog } = useUserGroupDialog()


  let { user_id } = useParams()
  if (user_id === undefined) {
    user_id = loggedInUser.user_id
  }

  const navigate = useNavigate()

  useEffect(() => {
    
    fetchItems()
    fetchUserGroups()
    fetchAllUserGroups()

  }, [user_id]);

  const fetchItems = async () => {
    
    userService.getById(user_id)
      .then(res => {

        setUser(res.users[0]);
        setLoading(false)
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const fetchUserGroups = async () => {
    
    userGroupService.getByUserId(user_id)
      .then(res => {

        setUserGroups(res.user_groups);
        setLoadingUserGroups(false)
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const fetchAllUserGroups = async () => {
    
    userGroupService.getAll()
      .then(res => {

        setUserGroupsAll(res.user_groups);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}

    await userService.update(users)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      })
  }

  const deleteUser = async data => {    

    await userService.destroy({users: data})
      .then(async res => { 

        showMessage(res.message)
        hideModalDialog()

        // Redirect to parent page
        navigate('/' + appName + '/users')

      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const editUserGroup = async data => {
    const userGroups = {user_groups: (Array.isArray(data) ? data : [data])}

    await userGroupService.update(userGroups)
      .then(async res => { 

        fetchUserGroups()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      })
  }

  const checkDeleteUser = async (items) => {    
    return userService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const updateMember = async event => {
    let { value } = ""

    value = event.value
    const userId = parseInt(user_id)

    const existingUser = userGroups.find(x => x.user_group_id === value)
    const user_group_members = [{ user_id: userId, user_group_id: value, user_group_member_id: existingUser?.user_group_member_id }]

    if (existingUser) {
      await removeMember(user_group_members)
    } else {
      await addMember(user_group_members)
    }

  }
  const addMember = async data => {
    //let { name, value } = ""

    //name = event.name
    //value = event.value
    //const userGroupId = parseInt(user_group_id)

    //const user_group_members = { user_group_members: [{ user_id: value, user_group_id: userGroupId }] }


    await userGroupMemberService.create({user_group_members: data})
      .then(async res => { 
        
        fetchUserGroups()

        showMessage(res.message)

      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const removeMember = async (data) => {    

    await userGroupMemberService.destroy({user_group_members: data})
      .then(async res => { 

        hideModalDialog()
        clearSelectedItems()
        fetchUserGroups()
        showMessage(res.message)

      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const morphButtonToggle = () => {
    setMorphButtonState(!morphButtonState)
    clearSelectedItems()
  }

  const requestResults = userGroupsAll.map(result => {

    return {
            value: result.user_group_id, 
            label: result.user_group_name,  
            tooltip: result.user_group_name
          }
  })

  const morphButton = () => {
    return (
      <div className={ "morph-button-wrapper " + ((morphButtonState) ? "show" : "") }> 
        <div className="morph-button-content">
          <form className="form" onSubmit={e => e.preventDefault() }>
            <Select 
              name="user_id"
              value={ userGroups.map(x => {return {value: x.user_group_id, label: x.user_group_name}}) }
              options={ requestResults }
              placeholder="Search User Groups..."
              isMulti={true}
              isClearable={true}
              onChange={ updateMember } 
              onBlur={ morphButtonToggle }
              setFocus={ morphButtonState }
            />
        
          </form>
        </div>
        <button type="button" className="morph-button-toggle main-toolbar-item button main-button" onClick={ morphButtonToggle }><span>ADD +</span></button>
      </div>
      )
  }

  const data = loading ? [] : user

  const editableAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin)
                            
  const userGroupTypeValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="USER"
          title={data.user_fullname}
          secondaryTitle={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
          subtitleText={ data && !loading &&
            data.user_username
          }
          photo={data.user_photo}
          loading={loading}
          >

          <div className="contact-info-container">
            <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_email) ? <React.Fragment><Icon name="email" />-</React.Fragment> : <a className="link" href={"mailto:"+data.user_email}><Icon name="email" />{data.user_email}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>

              <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_phone) ? <React.Fragment><Icon name="phone" />-</React.Fragment> : <a className="link" href={"tel:"+data.user_phone}><Icon name="phone" />{formatData(data.user_phone, 'phone')}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>

              <div className="display-linebreak contact-info">
              { !loading 
                ? (!data.user_linkedin_vanity_name) ? <React.Fragment><Icon name="linkedin" />-</React.Fragment> : <a className="link display-linebreak" target="_blank" href={"https://www.linkedin.com/in/" + data.user_linkedin_vanity_name}><Icon name="linkedin" />{"https://www.linkedin.com/in/" + data.user_linkedin_vanity_name}</a>
                : <SkeletonLoader width="10vw" />
              }
              </div>
            </div>
            
            <h3>{ !loading ? "Role" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_role_name === null) ? "-" : data.user_role_name
              : <SkeletonLoader width="10vw" />
            }
            </div>

            <h3>{ !loading ? "User Type" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_auth_method === null) ? "-" : authMethod[data.user_auth_method]
              : <SkeletonLoader width="10vw" />
            }
            </div>

            { editable &&
              <React.Fragment>

                { data.user_auth_method !== 'local' &&
                  <React.Fragment>
                    <h3>{ !loading ? "User Source Id" : <SkeletonLoader width="5vw"/>}</h3>
                    <div className="display-linebreak">
                    { !loading 
                      ? (data.user_source_id === null) ? "-" : data.user_source_id
                      : <SkeletonLoader width="10vw" />
                    }
                    </div>   
                  </React.Fragment>    
                }

                <h3>{ !loading ? "Status" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_is_disabled) 
                    ? <span className="pill pill-red">Disabled</span> 
                    : <span className="pill">Active</span>
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "Last Login" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_last_login_timestamp === null) ? <span className="no-result-text">Never logged in</span> : formatData(data.user_last_login_timestamp, 'datetime')
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "Added" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? formatData(data.created_timestamp, 'datetime')
                  : <SkeletonLoader width="10vw" />
                }
                </div>    

              </React.Fragment>
            }
          </ObjectDetails>
            
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item main-button button" onClick={ () => showEditUserDialog([data], editUser) }><span>EDIT</span></button>
            {loggedInUser && data.user_id !== loggedInUser.user_id &&
            <button type="button" className="main-toolbar-item button" onClick={ () => showDeleteUserDialog([data], deleteUser, checkDeleteUser) }><span>DELETE</span></button>
            }
          </div>
          }

      </MainColumn>
      
      <div className="column">
      
        <Tabs className="slim left" 
              disableTabsWithoutResults={true}
              defaultSelectedTab={defaultSelectedTab}>

        <div label="User Groups" tabid='user_groups' resultCount={loadingUserGroups ? undefined : userGroups.length}>

         <DataTable
          columns={[
            {id: 'user_group_photo', name: '', type: 'group-photo'},
            {id: 'user_group_name', name: 'Name', link: '/'+appName+'/user_groups/:user_group_id', className:"bold"},
            {id: 'user_group_default_role_name', name: 'Default Role'},  
            {id: 'user_group_type', name: 'Type', value:'user_group_type', values: userGroupTypeValues},
            ]}
          buttons={[ 
            {customButton: morphButton(), action: 'add', onClick: () => {} },
            {label: "Edit", action: "edit", onClick: (items) => showEditUserGroupDialog(items || selectedItems, editUserGroup) },
            {label: "Remove", action: "remove", tooltip: "Remove user from this user group. The User itself will not be deleted.", onClick: (items) => showRemoveUserDialog(items || selectedItems, removeMember) }
          ]}
          data={userGroups}
          title="User Groups"
          idColumn='user_group_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loadingUserGroups}
          editable= {editable}
          filterable={true}
          filterObjects={['user_group_name', 'user_group_default_role_name']}
        />

        </div>
      </Tabs>
        
      </div>

    </div>
  ) 
}
