import { useState, useMemo } from 'react';
import { useQueryParams, useDebounce } from './';

export const useFilterableData = (items, filterObjects=[], filterObjectsValueMap={}, defaultFilterTerm="") => {

  const { queryParams, setHistory } = useQueryParams()
  const [internalFilterTerm, setInternalFilterTerm] = useState(defaultFilterTerm || queryParams.filter);
  const [filterTerm, setFilterTerm] = useState(defaultFilterTerm || queryParams.filter);

  const _setInternalFilterTerm = (filterTerm) => {
    setInternalFilterTerm(filterTerm) 
  }

  const debouncedSetInternalFilterTerm = useDebounce(_setInternalFilterTerm, 200).debounce

  const filteredItems = useMemo( () => {
    
    let filterableItems = items ? [...items] : []

    if (internalFilterTerm.length > 1) {
      filterableItems = filterableItems.filter(item => {
       
        let match = false

        filterObjects.forEach(object => {
          let key = item[object]

          if (typeof key == "boolean") {
            key = key ? "true" : "false"
          }

          if ( key && (
               key.toLowerCase().replace(/_/g, " ").includes(internalFilterTerm) || 
               key.toLowerCase().includes(internalFilterTerm) ||
               (filterObjectsValueMap[object] && filterObjectsValueMap[object][key].label.toLowerCase().includes(internalFilterTerm))
             ) ) {
            match = true
          }
        })
        return match
        
      })
    }

    return filterableItems

  }, [items, internalFilterTerm]);

  const requestFilter = (str) => {

    const string = str ||  ""
    setFilterTerm(string)
    debouncedSetInternalFilterTerm(string.toLowerCase())
    setHistory({'filter': string})
  };

  return { filteredItems, requestFilter, filterTerm };
}