import React, { useState, useEffect } from 'react';
import { taskService } from '../services';
import { DataTable } from '../../../components';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { useTaskDialog } from '../hooks';

import { Role, formatDuration } from '../../../helpers';

export const Tasks = () => {

  const [tasks, setTasks] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showAddTaskDialog, showEditTaskDialog, showDeleteTaskDialog } = useTaskDialog()

  useEffect(() => {
    fetchItems();

  }, []);

  const fetchItems = async () => {
    
    taskService.getAll()
      .then(res => {

        let maxValue = 0

        res.tasks.forEach( row => {
          if (row.job_runtime_seconds > maxValue) {
            maxValue = row.job_runtime_seconds
          }
        })

        const tasks_tmp = res.tasks.map(task => {
          return {
          ...task, 
          job_runtime_seconds_max_value: maxValue,
          job_runtime_tooltip: formatDuration(task.job_runtime_seconds) + " (hh:mm:ss)",
          job_completion_message: (task.job_status === 'running' ? 'Running. Job started at ' + new Date(task.job_started_timestamp).toLocaleString('sv-SE') : task.job_completion_message)
          }
        })
        setTasks(tasks_tmp);
        setLoading(false);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const updateJobStatus = (task_arr, setNewState, jobs) => {
    
    let tasks_tmp = task_arr
    let i = -1
    let tmp = null

    jobs.forEach(job => {
      i = task_arr.findIndex(x => x.task_id === job.task_id)
      tmp = tasks_tmp[i]
      tasks_tmp[i] = {
        ...tmp, 
        job_status: job.job_status, 
        job_completion_message: job.job_completion_message
        }
    })
    setNewState([...tasks_tmp]); 
  }

  const addTask = async data => {
    const tasks = {tasks: (Array.isArray(data) ? data : [data])}

    await taskService.create(tasks)
      .then(res => {
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const editTask = async data => {
    const tasks = {tasks: (Array.isArray(data) ? data : [data])}

    await taskService.update(tasks)
      .then(async res => { 
        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const runTask = async data => {
    const selectedTasks = data || selectedItems

    selectedTasks.map(async task => {
      updateJobStatus(tasks, setTasks, [{task_id: task.task_id, job_status: 'running', job_completion_message: 'Running. Job started at ' + new Date().toLocaleString('sv-SE')}])
      updateJobStatus(selectedItems, setSelectedItems, [{task_id: task.task_id, job_status: 'running', job_completion_message: 'Running. Job started at ' + new Date().toLocaleString('sv-SE')}])

      await taskService.run(task.task_id)
        .then(res => { 
          updateJobStatus(tasks, setTasks, [{task_id: task.task_id, job_status: res.job.job_status, job_completion_message: res.job.job_completion_message}])
          updateJobStatus(selectedItems, setSelectedItems, [{task_id: task.task_id, job_status: res.job.job_status, job_completion_message: res.job.job_completion_message}])
          showMessage(res.message)
        })
        .catch(err => {
          updateJobStatus(tasks, setTasks, [{task_id: task.task_id, job_status: (err.job ? err.job.job_status : 'failed'), job_completion_message: err?.job?.job_completion_message}])
          updateJobStatus(selectedItems, setSelectedItems, [{task_id: task.task_id, job_status: (err.job ? err.job.job_status : 'failed'), job_completion_message: err?.job?.job_completion_message}])

          showMessage(JSON.stringify(err), 'error')});
    })
  }

  const deleteTask = async data => {    

    await taskService.destroy({tasks: data})
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const taskIsDisabledValues = {true: {label: 'Disabled', className: 'pill pill-red'}, false: {label: 'Active', className: 'pill'}}

  return (
    <div className="Tasks wide-margin">

        <DataTable
          columns={[
            {id: 'job_status', name: '', type: 'icon', tooltip: 'job_completion_message'},
            {id: 'task_name', name: 'Name', link: ':task_id', tooltip: 'task_description', className:"bold"},
            {id: 'task_type_name', name: 'Task Type', tooltip: 'task_type_description'},  
            {id: 'task_priority', name: 'Priority', type: 'integer', align: 'right', className:"prio-2"}, 
            {id: 'job_completed_timestamp', name: 'Last Run', type: 'datetime', className:"prio-2"},
            {id: 'job_runtime_seconds', name: 'Runtime [s]', type: 'chart', chartType: 'bar', data: {data:'job_runtime_seconds', max: 'job_runtime_seconds_max_value'}, tooltip: 'job_runtime_tooltip', className:"prio-2"},
            {id: 'last_success_completed_timestamp', name: 'Last Successful Run', type: 'datetime', className:"prio-2"},
            {id: 'task_is_disabled', name: 'Status', type: 'boolean', values: taskIsDisabledValues}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showAddTaskDialog(items || selectedItems, addTask) },
            {label: "Run", action: "run", onClick: runTask, disabled: {field: "job_status", value: "running", label: "running...", tooltip: "One or more selected tasks are already running" }},
            {label: "Edit", action: "edit", onClick: (items) => showEditTaskDialog(items || selectedItems, editTask) },
            {label: "Delete", action: "delete", onClick: (items) => showDeleteTaskDialog(items || selectedItems, deleteTask) }
          ]}
          data={tasks}
          title="Tasks"
          idColumn='task_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          filterable={true}
          filterObjects={['task_name', 'task_type_name','datasource_name', 'task_is_disabled']}
          filterObjectsValueMap={{task_is_disabled: taskIsDisabledValues}}

        />

      </div>
  );
}