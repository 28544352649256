import React, { useState, useEffect, useRef } from 'react';
import { userService } from '../../../services';
import { DataTable } from '../../../components';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { useUserDialog } from '../hooks';

import { Role, authMethod } from '../../../helpers';

export const Users = () => {

  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showAddUserDialog, showEditUserDialog, showDeleteUserDialog } = useUserDialog()

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    await userService.getAll()
      .then(res => {
        setUsers([...res.users]); 
        setLoading(false);
      })
      .catch(err => {
        setLoading(false) 
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const addUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}
    
    await userService.create(users)
      .then(res => {

        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}

    await userService.update(users)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const deleteUser = async data => {    

    await userService.destroy({ users: data })
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })

  }

  const checkDeleteUser = async (items) => {    
    return userService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const userAuthMethodValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 
  const userIsDisabledValues = {true: {label: 'Disabled', className: 'pill pill-red'}, false: {label: 'Active', className: 'pill'}}

  return (
    <div className="Users wide-margin">

        <DataTable
          columns={[
            {id: 'user_photo', name: '', type: 'user-photo', tooltip: 'user_fullname'},
            {id: 'user_fullname', name: 'Name', link: ':user_id', className:"bold"},
            {id: 'user_username', name: 'Username', className:"prio-2"},  
            {id: 'user_role_name', name: 'Role'},
            {id: 'user_last_login_timestamp', name: 'Last Login', type: 'datetime', className:"prio-2"},
            {id: 'user_auth_method', name: 'Type', value:'user_auth_method', values: userAuthMethodValues, className:"prio-2"},
            {id: 'user_is_disabled', name: 'Status', type: 'boolean', values: userIsDisabledValues}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showAddUserDialog(items || selectedItems, addUser) },
            {label: "Edit", action: "edit", onClick: (items) => showEditUserDialog(items || selectedItems, editUser) },
            {label: "Delete", action: "delete", onClick: (items) => showDeleteUserDialog(items || selectedItems, deleteUser, checkDeleteUser) }
          ]}
          data={users}
          title="Users"
          idColumn='user_id'
          primaryColumn='user_fullname'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['user_fullname', 'user_username','user_email', 'user_role_name', 'user_auth_method', 'user_is_disabled']}
          filterObjectsValueMap={{user_auth_method: userAuthMethodValues, user_is_disabled: userIsDisabledValues}}
        />

      </div>
  );
}
