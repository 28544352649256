import { useState, useEffect } from 'react'
import { subjects } from '../helpers'

export const useGlobalState = (subjectName) => {

  const subject = subjects[subjectName]
  const [value, setValue] = useState(subject.getValue())

  useEffect(() => {
   const sub = subject.subscribe(s => {setValue(s)})
   if (subject === "loggedInUser") {
    console.log(value)
   }
   return () => sub.unsubscribe()
  }, [value]);

  const newSetValue = (newValue) => {
    subject.next(newValue)
  }
  return [value, newSetValue]
}