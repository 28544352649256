import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Asset, Role, copyToClipboard, formatData } from '../../../helpers';
import { Icon, ObjectDetails, SkeletonLoader, Button, Checkbox } from '../../../components';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { 
  userService 
} from '../../../services';


export const Contact = () => {

  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const { showMessage } = useMessage()


  let { user_id } = useParams()
  
  const navigate = useNavigate()

  useEffect(() => {
    
    fetchItems()

  }, [user_id]);

  const fetchItems = async () => {
    
    userService.getById(user_id)
      .then(res => {

        setUser(res.users[0]);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')})
      .finally(setLoading(false))
  }

  const goBack = (e) => {
    // Prevent click event to fire on parent element
    e.stopPropagation()

    // Go back to previous page in browser history
    navigate(-1)
  }

  const data = loading ? [] : user

  return (
    <div className={"Home"}>
      <div className="columns narrow-margin profile">
        <div className="column contact-card">

          <button 
            className="button-back" 
            onClick={(e) => goBack(e)} 
          >
            <span className="button-back-arrow"></span>
            <span className="button-back-label">Back</span>
          </button>

          <ObjectDetails
            type="USER"
            title={data.user_fullname}
            photo={data.user_photo}
            subtitleText={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
            loading={loading}
            >

              <div className="contact-buttons">

              { !loading 
                ? data.user_phone && <a className="contact-button" href={"tel:"+data.user_phone}>
                    <div className="contact-button-icon">
                      <Icon name="phone" />
                    </div>
                    <div className="contact-button-label">
                      call
                    </div>
                  </a>
                : <div className="contact-button"><SkeletonLoader width="44px" height="44px" /></div>
              }

              { !loading 
                ? data.user_phone && <a className="contact-button" href={"sms:"+data.user_phone}>
                    <div className="contact-button-icon">
                      <Icon name="sms" />
                    </div>
                    <div className="contact-button-label">
                      message
                    </div>
                  </a>
                : <div className="contact-button"><SkeletonLoader width="44px" height="44px" /></div>
              }

              { !loading 
                ? data.user_email && <a className="contact-button" href={"mailto:"+data.user_email}>
                    <div className="contact-button-icon">
                      <Icon name="email" />
                    </div>
                    <div className="contact-button-label">
                      email
                    </div>
                  </a>
                : <div className="contact-button"><SkeletonLoader width="44px" height="44px" /></div>
              }


              { !loading 
                ? data.user_linkedin_vanity_name && <a className="contact-button" target="_blank" href={"MSTeams:/l/chat/0/0?users=" + data.user_email}>
                    <div className="contact-button-icon">
                      <Icon name="teams" />
                    </div>
                    <div className="contact-button-label">
                      teams
                    </div>
                  </a>
                : <div className="contact-button"><SkeletonLoader width="44px" height="44px" /></div>
              }

              { !loading 
                ? data.user_linkedin_vanity_name && <a className="contact-button" target="_blank" href={"https://www.linkedin.com/in/" + data.user_linkedin_vanity_name}>
                    <div className="contact-button-icon">
                      <Icon name="linkedin" />
                    </div>
                    <div className="contact-button-label">
                      linkedin
                    </div>
                  </a>
                : <div className="contact-button"><SkeletonLoader width="44px" height="44px" /></div>
              }

              </div>

          </ObjectDetails>

          <div className="contact-info-container">
            <h3>{ !loading ? "Phone" : <SkeletonLoader width="50px"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_phone === null) ? "-" : formatData(data.user_phone, 'phone')
              : <SkeletonLoader width="200px" />
            }
            </div>

            <h3>{ !loading ? "Email" : <SkeletonLoader width="50px"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_email === null) ? "-" : data.user_email
              : <SkeletonLoader width="200px" />
            }
            </div>

            <h3>{ !loading ? "Linkedin" : <SkeletonLoader width="50px"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_linkedin_vanity_name === null) ? "-" : "https://www.linkedin.com/in/"+data.user_linkedin_vanity_name
              : <SkeletonLoader width="200px" />
            }
            </div>
          </div>

        </div>

        <div className="column">
        </div>

      </div>
    </div>
  );
}

