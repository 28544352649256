import React, { useState, useEffect } from 'react';
import { userGroupService } from '../services';
import { DataTable, SideDialog } from '../../../components';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { Role, authMethod } from '../../../helpers';
import { useUserGroupDialog } from '../hooks';

export const UserGroups = () => {

  const [userGroups, setUserGroups] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showAddUserGroupDialog, showEditUserGroupDialog, showDeleteUserGroupDialog } = useUserGroupDialog()

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    const promise = new Promise( (resolve,reject) => {
      userGroupService.getAll()
        .then(res => {
          setUserGroups([...res.user_groups]); 
          setLoading(false);
          resolve(res.user_groups)
        })
        .catch(err => {
          setLoading(false) 
          showMessage(JSON.stringify(err), 'error')
        })
    })

    Promise.all([promise, fetchMemberCount()])
      .then( result => {
        updateMemberCount(result[0], result[1])
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')})
  }

  const fetchMemberCount = async () => {
    return new Promise(  (resolve, reject) => {
      
      userGroupService.getMemberCount()
        .then(res => {
          resolve(res.member_count)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateMemberCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.member_count > maxValue) {
        maxValue = row.member_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.user_group_id === row.user_group_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        member_count: (statistic ? statistic.member_count : 0), 
        member_count_max: maxValue
      }
    })
    setUserGroups([...dataTmp])
  }

  const addUserGroup = async data => {
    const user_groups = {user_groups: (Array.isArray(data) ? data : [data])}

    await userGroupService.create(user_groups)
      .then(res => {

        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const editUserGroup = async data => {
    const user_groups = {user_groups: (Array.isArray(data) ? data : [data])}

    await userGroupService.update(user_groups)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        clearSelectedItems()
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const deleteUserGroup = async data => {    

    await userGroupService.destroy({ user_groups: data })
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const checkDeleteUserGroup = async (items) => {    
    return userGroupService.checkDestroy(items)
      .then(async res => { 
        return res
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const userGroupTypeValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 

  return (
    <div className="Users wide-margin">

        <DataTable
          columns={[
            {id: 'user_group_photo', name: '', type: 'group-photo', tooltip: 'user_group_name'},
            {id: 'user_group_name', name: 'Name', link: ':user_group_id', className:"bold"},
            {id: 'member_count', name: 'Members', type: 'chart', chartType: 'bar', data: {data:'member_count', max: 'member_count_max'}},         
            {id: 'user_group_default_role_name', name: 'Default Role'},  
            {id: 'user_group_type', name: 'Type', value:'user_group_type', values: userGroupTypeValues, className:"prio-2"},
            ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showAddUserGroupDialog(items || selectedItems, addUserGroup) },
            {label: "Edit", action: "edit", onClick: (items) => showEditUserGroupDialog(items || selectedItems, editUserGroup) },
            {label: "Delete", action: "delete", onClick: (items) => showDeleteUserGroupDialog(items || selectedItems, deleteUserGroup, checkDeleteUserGroup) }
          ]}
          data={userGroups}
          title="User Groups"
          idColumn='user_group_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['user_group_name', 'user_group_default_role_name', 'user_group_type']}
          filterObjectsValueMap={{user_group_type: userGroupTypeValues}}
        />

      </div>
  );
}
