import React, { forwardRef } from 'react';
import { keyCode } from '../helpers';
import { useTooltip } from '../hooks'


const _Radio = ({name, onChange, checked, value, label, disabled=false, className, title, readOnly}, ref) => {
	
	const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        onChange();
        break;

      default:
        break;
    }
  }

	return (
		<label className={
			"CustomRadio" + 
			(className ? " " + className : "" ) + 
			(disabled || readOnly ? " disabled" : "" ) + 
			(checked ? " checked" : "" ) + 
			(readOnly ? " read-only" : "" ) 
		 }
		 tabIndex={ 0 }
		 onKeyDown={handleKeyDown}
		 ref={ref}
		>		
		  <input
		    type="radio"
		    name={ name }
		    value={ value }
		    onChange={ onChange }
		    checked={ checked ? true : false }
		    disabled={ disabled || readOnly }    
		  />
		  <span 
		  	className="label" 
		  	onMouseEnter={ () => title && showTooltip({title: title})} 
		  	onMouseLeave={ () => title && hideTooltip() }
		  >
		  {label}
		  {title && <span className="icon-description">i</span>}
		  </span>

		</label>
	)
}

export const Radio = forwardRef(_Radio);