import React from 'react';
import { Outlet } from 'react-router-dom'
import '../index.css';


export const App = ({appName}) => {

  return (
    <div className="app-container">

        <div className="app-content">
          <Outlet context={appName} />
        </div>

    </div>
  );
}