import config from '../../../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../../../helpers';
import { appName } from '../appConfig'

const endpointPath=`${appName}/question`

export const questionService = {
    getByQuestionnaireId,
    getByDefaultQuestionnaire
};

function getByQuestionnaireId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/questionnaire/${id}`, requestOptions, signal)
    .then(handleResponse)
}

function getByDefaultQuestionnaire(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/questionnaire/default`, requestOptions, signal)
    .then(handleResponse)
}