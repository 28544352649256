import React, { useState, useEffect, forwardRef } from 'react'
import { Checkbox, Input  } from '../../../components';
import { useGlobalState, useModalDialog } from '../../../hooks'

 const _EditProfileForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const [settings] = useGlobalState('settings');

	const initialFormState = {
		user_id: null,
		user_fullname: '',
		user_email: ''
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [accessToken, setAccessToken] = useState()

	useEffect(() => {

		if (data.length === 1) {
			setFormData({ 
				user_id: data[0].user_id,
				user_fullname: data[0].user_fullname || '',
				user_email: data[0].user_email || ''
			})
		} else {
			resetForm()		
		}
		
	}, [data]);
  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  setFormData({ ...formData, [name]: value })
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)

		// submit form
		submitForm(formData)
		.catch(err => {
			throw err
		})			 
		.finally(res => setLoading(false))   
	}

  const resetForm = () => {

  	setFormData(prevFormData => {
			return {...initialFormState 
						}
		}) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="user_fullname" 
		      	label="Full Name"
		      	value={formData.user_fullname} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		     	/>
	     	</div>

	     	<div className="form-block vertical">
		      <Input type="email" 
		      	name="user_email" 
		      	label="Email"
		      	value={formData.user_email} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
	      </div>

	    </form>
	  </div>
  )
}

export const EditProfileForm = forwardRef(_EditProfileForm)


