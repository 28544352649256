import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'
import { NewFeedbackForm, InviteFeedbackForm } from '../forms'

export const useFeedbackDialog = () => {

  const { showModalDialog } = useModalDialog()
  const newRef = useRef()
  const inviteRef = useRef()

  const showNewFeedbackDialog = (data, onSubmit) => {

    const config = {
      title: "Give Feedback",
      Content: NewFeedbackForm, 
      contentProps: {
        mode: "add",
        data: data,
        submitForm: onSubmit,
        ref: newRef
      },
      onSubmit: () => newRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
      submitLabel: "Ok"    }

    showModalDialog(config)
  }



  const showAddInviteDialog = (data, onSubmit) => {

    const config = {
      title: "Create Feedback Invitation",
      Content: InviteFeedbackForm,
      contentProps: {
        mode: "add",
        data: data,
        submitForm: onSubmit,
        ref: inviteRef
      },
      onSubmit: () => inviteRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
      submitLabel: "Save",
      submitLabelLoading: "Saving..."
    }

    showModalDialog(config)
  }

  const showDeleteInviteDialog = async (data, onSubmit, onCheckDelete) => {

    const checkDelete = await onCheckDelete({ invites: data })
    let config

    if (checkDelete.result.has_responses) { 
      config = {
        Content: () => <div>
            <div>{checkDelete.message}</div>
          </div>,
        cancelLabel: false,
        submitLabel: "Cancel",
        onCancel: false
      }
    } else {
      config = {
        Content: () => <div>
            <div>Are you sure you want to proceed with the following changes?</div>
            <ul>
            { data.length > 0 &&
              <li>{data.length + ' Invitation' + (data.length > 1 ? 's':'')} will be deleted. Any links to the {' invitation' + (data.length > 1 ? 's':'')} will no longer work.</li>
            }
            </ul>
          </div>,
        onSubmit: () => onSubmit(data),
        submitLabelLoading: "Deleting..."
      }
    }
    showModalDialog(config)
  }

  const showRejectInviteDialog = async (data, onSubmit) => {

    const config = {
      title: "Reject Feedback Invitation",
      Content: () => <div>
          <div>Are you sure you want to proceed with the following changes?</div>
          <ul>
          { data.length > 0 &&
            <li>{data.length + ' Invitation' + (data.length > 1 ? 's':'')} will be rejected. The person who sent the invitation will be notified.</li>
          }
          </ul>
        </div>,
      onSubmit: () => onSubmit(data),
      submitLabelLoading: "Deleting..."
    }
    showModalDialog(config)
  }

  return {
    showNewFeedbackDialog,
    showAddInviteDialog,
    showDeleteInviteDialog,
    showRejectInviteDialog
  }
}
