import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Asset } from '../../../helpers';
import { inviteService } from '../services';
import { DataTable } from '../../../components';
import { useModalDialog, useGlobalState, useMessage, useNotification } from '../../../hooks';
import { useFeedbackDialog } from '../hooks';


export const Requests = () => {

  const [invites, setInvites] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showNewFeedbackDialog, showRejectInviteDialog } = useFeedbackDialog()
  const { updateNotifications } = useNotification()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    await inviteService.getByToUserId(loggedInUser.user_id)
      .then(res => {
        setInvites(res.invites); 
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
      .finally(res => setLoading(false))
  }

  const rejectInvite = async data => {    

    await inviteService.reject({ invites: data.map(invite => invite.invite_id) })
      .then(async res => { 
        hideModalDialog()
        clearSelectedItems()
        fetchItems()
        updateNotifications()
        showMessage(res.message)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const newFeedback = props => {
    hideModalDialog()
    navigate(`${location.pathname}/give?user_id=${props.user_id}&questionnaire_id=${props.questionnaire_id}`)
  }

  const answerRequest = props => {
    navigate(location.pathname+"/give?token="+props)
  }

  return (
    <div className="Requests wide-margin">

      <DataTable
        columns={[
          {id: 'invite_id', name: 'Request', type: 'button', label: 'Respond', onClick: (props) => answerRequest(props), data: {data: 'invite_token'}},
          {id: 'user_photo', name: '', type: 'user-photo', tooltip: 'user_photo'},
          {id: 'user_fullname', name: 'From'},  
          {id: 'created_timestamp', name: 'Created At', type: 'datetime', className:"prio-2"},
          {id: 'questionnaire_name', name: 'Questionnaire Name', className:"prio-2"}
        ]}
        buttons={[ 
          {label: "Give +", action: 'add', mainButton: true, onClick: (items) => showNewFeedbackDialog(items || selectedItems, newFeedback) },
          {label: "Reject", action: "remove", onClick: (items) => showRejectInviteDialog(items || selectedItems, rejectInvite) }
        ]}
        data={invites}
        title="Feedback Requests"
        idColumn='invite_id'
        primaryColumn='invite_id'
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        loading={loading}
        editable= {true}
        filterable={true}
        filterObjects={['user_fullname']}
      />

    </div>
  );
}