import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  
  const location = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(location.search);

  const defaultHideDetailsPane  = (location.state?.hidedetails) || params.get('hidedetails') || false
  const defaultSearchTerm       = (location.state?.search) || params.get('search') ||  ""
  const defaultObject           = (location.state?.object) || params.get('object') || undefined
  const defaultFilterTerm       = (location.state?.filter) || params.get('filter') || ""
  const defaultTab              = (location.state?.tab) || params.get('tab') ||  undefined
  const defaultSort             = (location.state?.sort?.key) || params.get('sort') ||  undefined
  const defaultSortDirection    = (location.state?.sort?.direction) || params.get('sortdirection') ||  "asc"
  const defaultTablePage        = (location.state?.tablePage) || params.get('tablepage')
  const defaultTableScroll      = (location.state?.tableScroll) || 0

  const defaultSelectedItems    = (location.state?.selectedItems) ||  undefined
  const defaultNodeSize         = (location.state?.nodeSize) ||  undefined


  const setHistory = async (obj) => {

    if (obj.hidedetails && obj.hidedetails !== params.get('hidedetails')) {
      params.delete('hidedetails')
    }

    if (obj.search !== params.get('search')) {
      params.delete('search')
    }

    if (obj.tab && obj.tab !== params.get('tab')) {
      params.delete('tab')
      params.delete('object')
      params.delete('filter')
      params.delete('sort')
      params.delete('sortdirection')

      params.append('tab', obj.tab)
    }

    if (obj.object && obj.object !== params.get('object')) {
      params.delete('object')
      params.delete('filter')
      params.delete('sort')
      params.delete('sortdirection')

      params.append('object', obj.object)
    }

    if (obj.filter && obj.filter !== params.get('filter')) {
      params.delete('filter')

    }

    if (obj.sort && obj.sort.key !== params.get('sort')) {
      params.delete('sort')
      params.delete('sortdirection')
    }

    if (obj.sort && obj.sort.direction !== params.get('sortdirection')) {
      params.delete('sortdirection')
    }

    if (obj.tablePage !== params.get('tablepage')) {
      params.delete('tablepage')
    }

    // Replace url
    await navigate(location.pathname, {search: params.toString(), state: {...location.state, ...obj}, replace: true })

  }


  const queryParams = {
    hidedetails: (defaultHideDetailsPane === true || defaultHideDetailsPane === 'true') ? true : false,
    search: defaultSearchTerm,
    object: defaultObject,
    filter: defaultFilterTerm,
    tab: defaultTab,
    sort: defaultSort ? {key: defaultSort, direction: defaultSortDirection} : null,
    selectedItems: defaultSelectedItems,
    nodeSize: defaultNodeSize,
    tablePage: (defaultTablePage && parseInt(defaultTablePage)),
    tableScroll: defaultTableScroll
  }

  return { queryParams, setHistory }
}