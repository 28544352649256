import React, { useState, useLayoutEffect } from 'react';
import { keyCode, Role } from '../helpers';
import { Icon, NavLink } from './';
import { useTooltip, useGlobalState, useNotification } from '../hooks'


export const NavBar = ({links, appName}) => {

  const [showNavMenu, setShowNavMenu] = useState(true)
  const [notificationCount, setNotificationCount] = useState({});

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')

  const { showTooltip, hideTooltip } = useTooltip()
  const { notifications } = useNotification()

  useLayoutEffect( () => {
  	// Get Nav menu state from local storage
    setShowNavMenu(localStorage.getItem('showNavMenu') === "false" ? false : true)

  }, [])

  const toggleNavMenu = () => {
    const state = !showNavMenu

    setShowNavMenu(state)

    // Persist state in local storage
    localStorage.setItem('showNavMenu', state.toString())
  }

  const navMenuState = ((showNavMenu) ? 'show' : '');

  const renderLink = (link, key) => {
  	const type = link.type || "link"

  	switch (type) {
  		case "link":
  			return <NavLink 
  						end={(link.relativePath ? false: true)}
	  					key={key}
	  					to={link.relativePath} 
	  					className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") }
	  					tooltip={link.tooltip || link.label}
	  				 >
	  				  { link.icon && link.icon }
	  				  <span>{ link.label }</span>
	  				  { notifications && notifications[appName][link.relativePath] > 0 &&
	  				  	<div className="nav-link-notification"><span>{ notifications[appName][link.relativePath] }</span></div>
	  					}
	  				 </NavLink>
	  		break;
	  	case "section":
  			return (
  				<React.Fragment key={key}>
  					<div className="navbar-group-title grey-text"><span>{link.label}</span></div>
  					{ renderLinks(link.links) }
  				</React.Fragment>
  			)
  			break;
  		default:
  		break
  	}
  	
  }

  const renderLinks = (linkArray) => {
   	return linkArray.map((link, key) => {
   		if (link.roles) {
    		if ( loggedInUser && link.roles.includes(loggedInUser.user_role_name) ) {
    			return renderLink(link, key)
    		}
    	} else {
    		return renderLink(link, key)
    	}
    })
  }

	return (
		<div className={ "navbar " + navMenuState }>  

      <div className="navbar-content">
      	{ renderLinks(links) }
      </div>

			<div className="navbar-footer">
        <div 
	        className="navbar-toggle-button" 
	        onClick={ toggleNavMenu } 
	        onMouseEnter={ () => showTooltip((showNavMenu ? "Minimize" : "Expand") + " navigation menu")}
	      	onMouseLeave={hideTooltip}
      	>
      		<span></span>
      	</div>
      </div>
      
    </div>
	)
}
