import config from '../../../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../../../helpers';
import { appName } from '../appConfig'

const endpointPath=`${appName}/response`

export const responseService = {
    getByQuestionnaireResponseId,
    getByQuestionnaireResponseIds
};

function getByQuestionnaireResponseId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/questionnaire_response/${id}`, requestOptions, signal).then(handleResponse)
}

function getByQuestionnaireResponseIds(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/select_by_questionnaire_response_ids`, requestOptions, signal).then(handleResponse);
}
