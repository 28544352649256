import React, { useState, useEffect } from 'react';
import { BarChart, YAxis, XAxis, ZAxis, Bar, ResponsiveContainer, CartesianGrid, LabelList, Tooltip, Cell, ScatterChart, Scatter} from 'recharts'

import { useTooltip } from '../../../../../hooks'
import { Photo, Radio, Textarea, TextareaAutosize } from '../../../../../components'
import { renderText } from '../helpers'


const QuestionBarChart = ({n, yLabel="Respondents", questionnaireResponses, formData}) => {

  const [hoveredChartItem, setHoveredChartItem] = useState()
  const [data, setData] = useState()
  const [mean, setMean] = useState()

  useEffect( () => {

    let tmp = Array(n).fill(0)

    questionnaireResponses.forEach( questionnaireResponse => {
      //const questionnaireResponse = questionnaireResponses.filter(x => x.questionnaire_response_id === parseInt(questionnaireResponseId))[0]
      const res = formData.find(x => parseInt(Object.keys(x)[0]) === questionnaireResponse.questionnaire_response_id)

      if (res) {
        const [[questionnaireResponseId, value]] = Object.entries(res)
        const val = (tmp[value-1].value || 0) + 1
        const respondents = [...(tmp[value-1].respondents || []), ...[questionnaireResponse.respondent_name]]
        tmp[value-1] = {value: val, respondents: respondents}
      }
    })

    tmp = tmp.map( (item, index) => {
      return {x: index+1, y: item.value || 0, respondents: item.respondents}
    })

    setData(tmp)

    setMean([{
      x: Math.round( tmp.reduce((a, b) => a + b.y*b.x, 0) / questionnaireResponses.length * 10) / 10, 
      y: 1,
      z: 1
    }])
  }, [questionnaireResponses, formData])

  

  const ChartCustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      const respondents = payload[0].payload.respondents
      if (respondents) {

        const data = respondents.map( (item, index) => <div key={index} >{item}</div>)
      return <div className="chart-custom-tooltip">{data}</div>
      }
      
    }
    return null;
  };

  return data && mean && (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
          <XAxis dataKey="x" axisLine={false} tick={{ fill: "#222020" }} tickLine={false} tickMargin={8}/>
          <YAxis 
            tickMargin={5} 
            axisLine={false} 
            label={{ value: yLabel, angle: -90, position: 'center', fill: "#222020", dx: -20 }} 
            tick={{ fill: '#B8ADAB', fontSize: '0.9em'}} 
            tickLine={false} 
            domain={['0', 'dataMax + 1']}
            allowDecimals={false}
            />
             {<Tooltip content={<ChartCustomTooltip />} payload={data} isAnimationActive={false} allowEscapeViewBox={{x: false, y: true }} cursor={false} />
          }
          <CartesianGrid vertical={false} strokeDasharray="4" strokeWidth="0.5px"/>
          <Bar 
            dataKey="y" 
            barSize={30} 
            fill="#D096FD" 
            onMouseEnter={(bar) => setHoveredChartItem(bar.payload.x)}
            onMouseLeave={() => setHoveredChartItem()} 
            isAnimationActive={false}>
              <LabelList dataKey="y" position="insideTop" fill="#fff" fontSize="0.9em" formatter={(val) => val > 0 ? val : ""} />
              {data.map((entry, index) => {
                return (
                  <Cell key={`cell-${index}`} fill={hoveredChartItem === index+1 ? '#222020' : '#D096FD'} />
                )}
              )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={70}>
        <ScatterChart margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid vertical={false} horizontal={false} fill="#F5F2EF" />
          <XAxis dataKey="x" domain={[0.5, n + 0.5]} axisLine={false} type="number" interval={0} tick={false} allowDecimals={true}/>
          <YAxis dataKey="y" label={{ value: 'Mean', position: 'insideLeft', fill: "#222020" }} domain={[0, 2]} axisLine={false} tick={false} tickMargin={5} />
          <Scatter name="A school" data={mean} fill="#D096FD" isAnimationActive={false} 
          shape={(props) => <rect
      fill="#D096FD"
      x={props.x + 1.5}
      y={18}
      width="6"
      height="24"
    />}>
          <LabelList dataKey="x" position="bottom" offset={15} fill="#D096FD" className="chart-mean-label" fontSize="0.9em" />
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </React.Fragment>
  )

}

export const Question = ({ question, formData, onChange, props, disabled, readOnly, questionnaireResponses, number, showNumbers }) => {

  const { showTooltip, hideTooltip } = useTooltip()

  const renderLikert = (questionId, data, value, n) => {
    
    const large = true
    let alternatives = []

    for (let i = 0; i<n; i++) {
      alternatives.push(
        <div className="alternative" key={i}>
          <Radio 
            name={questionId} 
            value={i+1} 
            checked={value === (i+1).toString()} 
            onChange={onChange} 
            label={i+1} 
            className={ large ? "label-center" : "" }
            disabled={disabled}
            readOnly={readOnly} 
          />
        </div>
      )
    }

    if (large) {
      return (
        <div className="likert large">
        <div className="likert-labels">
          { data.question_min_label && <div className="likert-min-label">{data.question_min_label}</div>}
          { data.question_max_label && <div className="likert-max-label">{data.question_max_label}</div>}
        </div>
        <div className="alternatives">{ alternatives }</div>
        </div>
      )
    } else {
      return (
        <div className="likert">
        { data.question_min_label && <div className="likert-min-label">{data.question_min_label}</div>}
        <div className="alternatives">{ alternatives }</div>
        { data.question_max_label && <div className="likert-max-label">{data.question_max_label}</div>}
        </div>
      )
    }

  }

  const renderQuestion = (data, formData) => {
    let questionId = data.question_id
    let response

    if (!formData) return null

    if (Array.isArray(formData) && formData.length > 1) {
      switch (data.question_type_code) {
        case "text":

            response = questionnaireResponses.map( questionnaireResponse => {
            //const questionnaireResponse = questionnaireResponses.filter(x => x.questionnaire_response_id === parseInt(questionnaireResponseId))[0]
            const res = formData.find(x => parseInt(Object.keys(x)[0]) === questionnaireResponse.questionnaire_response_id)
            
            if (res) {
              const [[questionnaireResponseId, value]] = Object.entries(res)

              return (
                <div className="question-response" key={questionnaireResponseId}>
                  <div className="question-respondent">
                    <Photo data={questionnaireResponse.respondent_user_photo}/> 
                    <div className="question-respondent-name">{questionnaireResponse.respondent_name}</div>
                  </div>
                  <div className="question-response-value">{value}</div>
                </div>
              )
            }
            return null
          })

          break
        case "likert3":
          response = <QuestionBarChart n={3} questionnaireResponses={questionnaireResponses} formData={formData} />
          break
        case "likert5":
          //response = renderLikert(questionId, data, 5)
          response = <QuestionBarChart n={5} questionnaireResponses={questionnaireResponses} formData={formData} />
          break
        case "likert7":
          response = <QuestionBarChart n={7} questionnaireResponses={questionnaireResponses} formData={formData} />

          break
        case "likert9":
          response = <QuestionBarChart n={9} questionnaireResponses={questionnaireResponses} formData={formData} />
          break
        case "section":
          response = null
          break
        default:
        break
      }
    } else {
      const value = Array.isArray(formData) && formData.length > 0 ? Object.values(formData[0])[0] : formData[questionId]

      switch (data.question_type_code) {
        case "text":
          response = <TextareaAutosize name={questionId} value={value} onChange={onChange} disabled={disabled} readOnly={readOnly} minRows={5} />
          break
        case "likert3":
          response = renderLikert(questionId, data, value, 3)
          break
        case "likert5":
          response = renderLikert(questionId, data, value, 5)
          break
        case "likert7":
          response = renderLikert(questionId, data, value, 7)
          break
        case "likert9":
          response = renderLikert(questionId, data, value, 9)
          break
        case "section":
          response = null
          break
        default:
        break
      }
    }
    return <div className="question-body">{response}</div>

  }

  const renderTitle = (data, props, isMandatory, questionType) => {
    if (!data) return null

    let response

    switch (questionType) {
      case "section":
        response = <div className="question-title">{ renderText(data, props) }</div>
        break
      default:
        response = <div className="question-title">{ (showNumbers ? `${number}. ` : "") + renderText(data, props) + (isMandatory && !readOnly ? " *" : "") }</div>
        break
    }

    return response
  }

  const renderDescription = (data, props) => {
    if (!data) return null
    return <div className="question-description">{ renderText(data, props) }</div>
  }

  return question && (
    <div className={"Question " + question.question_type_code}>

      { renderTitle(question.question_title, props, question.question_is_mandatory, question.question_type_code) }

      { renderDescription(question.question_description, props) }

      { renderQuestion(question, formData) }
      
    </div>
  )
};


