import React from 'react';
import { 
  Icon,
  SkeletonLoader } from '../../components'
import { Role, subjects } from '../../helpers';
import { 
  Analyze,
  Give,
  Home,
  Invite,
  Requests,
  Result
} from './views';

export const routesConfig = (appName, displayName) => ([
  {
    path: "/" + appName + "/analyze",
    breadcrumb: "Analyze Feedback",
    element: Analyze
  },
  {
    path: "/" + appName + "/analyze/result",
    breadcrumb: "Received Feedback",
    element: Result
  },
  {
    path: "/" + appName + "/requests",
    breadcrumb: "Feedback Requests",
    element: Requests
  },
  {
    path: "/" + appName + "/requests/give",
    breadcrumb: "Give Feedback",
    element: Give
  },
  {
    path: "/" + appName + "/invite",
    breadcrumb: "Ask For Feedback",
    element: Invite
  },
  {
    path: "/" + appName + "/",
    breadcrumb: displayName,
    element: Home
  }
]);
