import { useRef } from 'react'
import { useModalDialog } from '../../../hooks'
import { EditProfileForm } from '../forms'

export const useProfileDialog = () => {

  const { showModalDialog, hideModalDialog } = useModalDialog()
  const editRef = useRef()

  const showEditProfileDialog = (data, onSubmit) => {
    const itemCount = data.length

    const config = {
          title: "Edit Profile",
          Content: EditProfileForm,
          contentProps: {
            mode: "edit",
            data: data,
            submitForm: onSubmit, 
            ref: editRef
          },
          onSubmit: () => editRef.current.dispatchEvent( new Event("submit", { cancelable: true, bubbles: true }) ),
          submitLabel: "Save",
          submitLabelLoading: "Saving..."
        }

    showModalDialog(config)
  }

  return {
    showEditProfileDialog
  }
}
