import { 
  Home,
} from './views';

export const routesConfig = (appName, displayName) => ([
  {
    path: "/" + appName + "/",
    breadcrumb: displayName,
    element: Home
  }
]);
