import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { feedbackService } from '../services';
import { userService } from '../../../services';
import { Checkbox, formatSelectOptions, Input, Select, SelectButtons } from '../../../components';
import { authMethod, getBatchEditValue, formatData } from '../../../helpers'
import { useGlobalState, useDebounce, useMessage } from '../../../hooks'
import { Questionnaire, IconFeedback } from '../components'
import { inviteService, questionnaireService } from '../services'


const _NewFeedbackForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		  user_id: null
		, questionnaire_id: null
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [invalid, setInvalid] = useState({})
	const [users, setUsers] = useState([]);
	const [questionnaires, setQuestionnaires] = useState([])

  const { showMessage } = useMessage()

  const navigate = useNavigate()

	useEffect( () => {
    fetchItems()
	}, []);

	useEffect(() => {

	   if (data && data.length === 1) {
			setFormData({ 
					user_id: data[0].user_id || null
				, questionnaire_id: data[0].questionnaire_id || null
			})
		} else if(mode === 'add') {
			
			const defaultQuestionnaire = questionnaires?.filter(x => x.questionnaire_is_default)

			setFormData({ 
				...formData, 
				questionnaire_id: defaultQuestionnaire.length > 0 && defaultQuestionnaire[0].questionnaire_id

			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, questionnaires]);


  const fetchItems = async () => {

    const promises = [
      fetchQuestionnaires(),
      fetchUsers()
    ]

    await Promise.all(promises).then(data => {
      setQuestionnaires(data[0])
      setUsers(data[1])
    })
    .catch(err => showMessage(err, 'error'))

  }

  const fetchQuestionnaires = () => {
    return new Promise( async (resolve, reject) => {
      try {

        const res = await questionnaireService.getAll()
        resolve(res.questionnaires)

      } catch (err) {
        reject(err)
      };
    })
  }

 	const fetchUsers = async () => {
		return new Promise( async (resolve, reject) => {
      try {

    		const res = await userService.getAll()
        resolve(res.users.filter(x => x.user_id !== loggedInUser.user_id))

      } catch (err) {
        reject(err)
      };
    })
  }
  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  let invalidForm = {...invalid}
	  delete invalidForm[name]
	  setInvalid(invalidForm)
	  setFormData({ ...formData, [name]: value })
	}

	const onSubmit = event => {

		event.preventDefault()

		setLoading(true)

		let dataToSubmit = formData
		let invalidForm = {}

		if (!dataToSubmit.user_id) {
			invalidForm.user_id = {message: "You must select a colleague"}
		} 
		if (!dataToSubmit.questionnaire_id) {
			invalidForm.questionnaire_id = {message: "You must select a questionnaire"}
		} 

		if (Object.keys(invalidForm).length > 0) {
			setInvalid(invalidForm)
		} else {
			// submit form
			submitForm(dataToSubmit)
		}

		setLoading(false)
		    
	}

  const cancelForm = () => {
  	navigate(-1)
  }

  const resetForm = () => {
  	setFormData({
  		...initialFormState
			, user_id: loggedInUser.user_id
			, questionnaire_id: formData.questionnaire_id
		}) 
  }

	return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>

	      <div className="form-block vertical">
					<label>Colleague</label>
					<Select 
            name="user_id"
            value={formData.user_id}
            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip:"user_email"}) }
            onChange={handleInputChange} 
            disabled={loading}
            invalid={invalid.user_id}
            placeholder='Select a colleague'
          />
		    </div>

		    <div className="form-block vertical">
					<label>Questionnaire</label>
					<Select 
            name="questionnaire_id"
            value={formData.questionnaire_id}
            options={ formatSelectOptions({options: questionnaires, optionValue: "questionnaire_id", optionLabel: "questionnaire_name", tooltip:"questionnaire_title"}) }
            onChange={handleInputChange} 
            disabled={loading}
            invalid={invalid.questionnaire_id}
            placeholder='Select a questionnaire'
          />
		    </div>

		    { Object.keys(invalid).length > 0 &&
			    <div className="form-block form-error">
			    { Object.entries(invalid).map( ([key, value]) => (<p key={key}>{value.message}</p>))}
			    </div>
			  }
	    </form>
	  </div>
  )
}

export const NewFeedbackForm = forwardRef(_NewFeedbackForm)


