import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useTooltip } from '../hooks'
import { Icon } from './'


const _Input = ({
	name, 
	type, 
	placeholder, 
	label, 
	labelAsPlaceholder, 
	value, 
	disabled=false, 
	isNullable=false, 
	onChange, 
	onBlur, 
	onFocus, 
	autoComplete, 
	required=false, 
	className, 
	icon, 
	iconAsButton=false, 
	validation,
	title,
	labelPosition
}, ref) => {
	
	const {showTooltip, hideTooltip} = useTooltip()

	const [isActive, setIsActive] = useState(false)
	const [expanded, setExpanded] = useState(value ? true : false )
  
  const tmpRef = useRef()
  const inputRef = ref || tmpRef

  useEffect( () => {
  	if (!value || value === '') {
	    setIsActive(false);
	  } else {
	    setIsActive(true);
	  }
  }, [value])

  const clearInput = () => {
  	onChange({value: "", name: name})
		inputRef.current.focus()
  }

  const handleExpand = (el) => {
  	onFocus && onFocus(el)

		inputRef.current.focus()
  	setExpanded(true)
  	
  }
  const handleBlur = (el) => {
  	if (iconAsButton && value === "") {
  		setExpanded(false)
  	}
  	onBlur && onBlur(el)
  }

	return (
		<div className={
			"CustomInput" + 
			(className ? " "+className : "") + 
			(labelAsPlaceholder ? " label-as-placeholder" : "") + 
			(icon ? " with-icon" : "") + 
			(iconAsButton && !expanded ? " collapsed" : "") + 
			(validation && !validation.valid ? " has-error" : "") + 
			(labelPosition ? " label-position-" + labelPosition : "")
		} >
		  { label && 
		  	<label 
		  		className={ isActive ? "active" : ""} 
		  		htmlFor={name} 
		  		onMouseEnter={ () => title && showTooltip({title: title})} 
		  		onMouseLeave={ title && hideTooltip }
		  	>
		    	<span>{label}</span>
		    	{title && <span className="icon-description">i</span>}
		  	</label>
			}
			<div className="input-container" onClick={handleExpand}>
			{ icon &&
				<div className="input-icon">
					<Icon name={icon} />
				</div>
			}
			  <input
					type={type}
					ref={inputRef}
	        name={name}
	        placeholder={labelAsPlaceholder ? '' : placeholder}
	        value={value}
	        onChange={onChange}
	        disabled={disabled}
	        autoComplete={autoComplete}
	        onBlur={handleBlur}
	        onFocus={handleExpand}
	        required={(required || required === 'required') ? 'required': false}
			  />

				{ isNullable && value !== "" &&
				  <div 
				    className="button-clear-input" 
				    onClick={ clearInput }
				    tabIndex= {1}
				  >
				    <div className="button-clear-input-icon"></div>
				  </div>
				}

				{ validation &&
				  <div 
				    className={"validation-icon" + (validation.valid ? " valid" : " error") }
				    onMouseEnter={ () => showTooltip({title: validation.message})} 
		  			onMouseLeave={ hideTooltip }
				  >
				  </div>
				}
		  </div>
		</div>
	)
}

export const Input = forwardRef(_Input);