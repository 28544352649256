import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath='user'

export const userService = {
    getAll,
    getById,
    getFromAD,
    getPhotos,
    create,
    update,
    updateProfile,
    changePassword,
    checkDestroy,
    destroy
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentUser.next(res.users[0]);
        return res
    });
}

function getPhotos(userIds, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
        body: JSON.stringify({userIds: userIds}) 
    };

    return customFetch(`${config.api.url}/${endpointPath}/photos`, requestOptions, signal).then(handleResponse)

}

function getFromAD(search, access_token, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
        body: JSON.stringify({access_token: access_token}) 
    };

    return customFetch(`${config.api.url}/${endpointPath}/search_ad/${search}`, requestOptions, signal, true, true).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function updateProfile(items, signal) {
    const requestOptions = { 
        method: 'PUT', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
        body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}/update_profile`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        console.log(res.users[0])
        subjects.loggedInUser.next(res.users[0]);
        return res
    });
}

function changePassword(items, signal) {
    const requestOptions = { 
        method: 'PUT', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
        body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}/change_password`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}