

export const renderText = (str, props) => {
  if (!str) return null
  if (!props) return str  

  let re = new RegExp("{{([a-zA-Z0-9_\.]*)}}","gi")
   
  let matches = []
  let params = []

  while(matches = re.exec(str)) {
    params.push(matches[1]);
  }

  if (params.length < 1) return str

  let map = {}
  params.forEach( match => {
    map = {...map, ["{{"+match+"}}"]: props[match] }
  })

  re = new RegExp(Object.keys(map).join("|"),"gi");

  str = str.replace(re, function(matched){
    return map[matched];
  });

  return str
};