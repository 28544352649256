import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useOutletContext } from 'react-router-dom';
import { taskService, jobService } from '../services';
import { formatData, formatDuration, Role, TaskType } from '../../../helpers';
import { 
  Button,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  SkeletonLoader,
  Tabs } from '../../../components';
import { useModalDialog, useGlobalState, useMessage } from '../../../hooks';
import { useTaskDialog } from '../hooks';

export const Task = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const appName = useOutletContext()

  const [task, setTask] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()
  const { showEditTaskDialog, showDeleteTaskDialog, showMessageDialog } = useTaskDialog()

  const { task_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchJobs()

  }, []);

  const fetchItems = async () => {
    
    taskService.getById(task_id)
      .then(res => {

        setTask(res.tasks[0]);
        setLoading(false)
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchJobs = async () => {
    
    jobService.getByTaskId(task_id)
      .then(res => {

        let maxValue = 0

        res.jobs.forEach( row => {
          if (row.job_runtime_seconds > maxValue) {
            maxValue = row.job_runtime_seconds
          }
        })

        const jobs_tmp = res.jobs.map(job => {
          return {
            ...job, 
            job_runtime_seconds_max_value: maxValue,
            job_runtime_tooltip: formatDuration(job.job_runtime_seconds) + " (hh:mm:ss)"          
          }
        })

        setJobs(jobs_tmp);
        setLoadingTable(false);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')});
  }

  const updateJobStatus = (task_arr, job) => {
    
    const task_tmp = {
      ...task, 
      job_status: job.job_status, 
      job_completion_message: job.job_completion_message
      }

    setTask(task_tmp); 
  }

  const editTask = async data => {
    const tasks = {tasks: (Array.isArray(data) ? data : [data])}

    await taskService.update(tasks)
      .then(async res => { 

        fetchItems()
        showMessage(res.message)
        hideModalDialog()
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const runTask = async () => {
    const selectedTasks = [task]

    selectedTasks.map(async task => {
      updateJobStatus([task], {task_id: task.task_id, job_status: 'running', job_completion_message: 'Running'})
      await taskService.run(task.task_id)
        .then(res => { 
          fetchJobs()
          updateJobStatus([task], {task_id: task.task_id, job_status: res.job.job_status, job_completion_message: res.job.job_completion_message})
          showMessage(res.message)
        })
        .catch(err => {
          fetchJobs()
          updateJobStatus([task], {task_id: task.task_id, job_status: err.job.job_status, job_completion_message: err.job.job_completion_message})
          showMessage(JSON.stringify(err), 'error')});
    })
  }

  const deleteTask = async data => {    

    await taskService.destroy({tasks: data})
      .then(async res => { 
        
        hideModalDialog()
        showMessage(res.message)

        // Redirect to parent page
        navigate('/' + appName + '/tasks')

      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
  }

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin
  
  const data = loading ? [] : task

  const isRunning = data.job_status === "running"

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Task"
          title={data.task_name}
          subtitleText={ data && !loading &&
            data.task_type_name + " Task"
          }
          loading={loading}
          >

            <div className="display-linebreak">
            { !loading 
              ? (data.task_description === "") ? <span className="no-result-text">No description available</span> : data.task_description
              : <SkeletonLoader width="10vw" count={2} />
            }
            </div>

            <h3>{ !loading ? "Priority" : <SkeletonLoader width="5vw"/>}</h3> 
            <div className="display-linebreak">
            { !loading 
              ? data.task_priority
              : <SkeletonLoader width="5vw" />
            }
            </div>
            
            <h3>{ !loading ? "Status" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.task_is_disabled) 
                  ? <span className="pill pill-disabled">Disabled</span>
                  : <span className="pill pill-enabled">Active</span>
              : <SkeletonLoader width="10vw" />
            }
            </div>

            <h3>{ !loading ? "Last Successful Run" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.last_success_completed_timestamp === null) ? "Never run" : formatData(data.last_success_completed_timestamp, 'datetime')
              : <SkeletonLoader width="10vw" />
            } 
            </div>

            <h3>{ !loading ? "Created" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? formatData(data.created_timestamp, 'datetime')
              : <SkeletonLoader width="10vw" />
            } 
            </div>
            </ObjectDetails>
          
            { !loading &&
            <div className="main-toolbar">
              <button type="button" className="main-toolbar-item button main-button" onClick={ () => showEditTaskDialog([data], editTask) }><span>EDIT</span></button>
              <Button className="main-toolbar-item button" disabled={isRunning} onClick={ runTask } value={isRunning ? "RUNNING..." : "RUN"}/>
              <button type="button" className="main-toolbar-item button" onClick={ () => showDeleteTaskDialog([data], deleteTask) }><span>DELETE</span></button>
            </div>
            }

      </MainColumn>

      <div className="column">
        <Tabs className="slim left">
          <div label="Jobs" tabId='jobs'>

            { jobs.length > 0 || loadingTable ?
            <DataTable
            columns={[
              {id: 'job_status', type: 'icon', tooltip:'job_status'},
              {id: 'job_started_timestamp', name: 'Started', type: 'datetime'},
              {id: 'job_completed_timestamp', name: 'Completed', type: 'datetime'},
              {id: 'job_runtime_seconds', name: 'Runtime [s]', type: 'chart', chartType: 'bar', data: {data:'job_runtime_seconds', max: 'job_runtime_seconds_max_value'}, tooltip: 'job_runtime_tooltip'},
              {id: 'job_execution_method', name: 'Execution Method', className:"prio-2"},
              {id: 'job_executed_by_user', name: 'Executed By', link: '/' + appName + '/users/:user_id', className:"prio-2"},
              {id: 'job_completion_message', name: 'Message', type: 'button', label: 'Show', onClick: (props) => showMessageDialog(props), data: {data: 'job_completion_message'}}
            ]}
            data={jobs}
            idColumn='job_id'
            loading={loadingTable}
            editable={false}
            filterable={true}
            filterObjects={['job_status', 'job_execution_method', 'job_executed_by_user', 'job_completion_message']}
          />
            : !loadingTable &&
              <div className="no-result-text">No jobs found for this task</div>
          }
        </div>
      </Tabs>
      
      </div>

    </div>
  ) 
}
