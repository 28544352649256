import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalState } from '../hooks'

export const PrivateRoute = ({ element: Component, roles}) => {

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  let location = useLocation()

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' state={{from: location}} replace={true} />
  }

  if (isLoggedIn && loggedInUser && loggedInUser.password_change_required) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/change_password' state={{from: location}} replace={true} />
  }

  // check if route is restricted by role
  if (roles && roles.indexOf(loggedInUser.user_role_name) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to='/' replace={true} />
  }

  // authorised so return component
  return <Component replace={true}/>

}