import React, { useState, useEffect, forwardRef, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { feedbackService } from '../services';
import { userService } from '../../../services';
import { Checkbox, formatSelectOptions, Input, Select, SelectButtons } from '../../../components';
import { authMethod, getBatchEditValue, formatData } from '../../../helpers'
import { useGlobalState, useDebounce, useMessage } from '../../../hooks'
import { Questionnaire, IconFeedback } from '../components'
import { inviteService, questionnaireService } from '../services'


const _InviteFeedbackForm = ({ mode, data, submitForm, loading, setLoading }, ref) => { 
	
	const initialFormState = {
		  user_id: null
		, invite_send_email: false
		, respondent_is_internal: true
		, respondent_user_id: null
		, respondent_name: ''
		, respondent_email: ''
		, questionnaire_id: null
	}

	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [users, setUsers] = useState([]);
	const [questionnaires, setQuestionnaires] = useState([])

  const { showMessage } = useMessage()

  const navigate = useNavigate()

	useEffect( () => {
    	fetchItems()
	}, []);

	useLayoutEffect(() => {

	   if (data.length > 0) {
			setFormData({ 
					user_id: loggedInUser.user_id
				, invite_send_email: data[0].invite_send_email || false
				, respondent_is_internal: data[0].respondent_is_internal ? true : false
				, respondent_user_id: data[0].respondent_user_id || null
				, respondent_name: data[0].respondent_name || ''
				, respondent_email: data[0].respondent_email || ''
				, questionnaire_id: data[0].questionnaire_id || null
			})

		} else if(mode === 'add') {
			
			const defaultQuestionnaire = questionnaires?.filter(x => x.questionnaire_is_default)

			setFormData({ 
				...formData, 
				user_id: loggedInUser.user_id,
				questionnaire_id: defaultQuestionnaire.length > 0 && defaultQuestionnaire[0].questionnaire_id
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, questionnaires]);

  const fetchItems = async () => {

    const promises = [
      fetchQuestionnaires(),
      fetchUsers()
    ]

    await Promise.all(promises).then(data => {
      setQuestionnaires(data[0])
      setUsers(data[1])
    })
    .catch(err => showMessage(err, 'error'))

  }

  const fetchQuestionnaires = () => {
    return new Promise( async (resolve, reject) => {
      try {

        const res = await questionnaireService.getAll()
        resolve(res.questionnaires)

      } catch (err) {
        reject(err)
      };
    })
  }

 	const fetchUsers = async () => {
		return new Promise( async (resolve, reject) => {
      try {

    		const res = await userService.getAll()
        resolve(res.users.filter(x => x.user_id !== loggedInUser.user_id))

      } catch (err) {
        reject(err)
      };
    })
  }
  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  setFormData({ ...formData, [name]: value })
	}

	const onSubmit = event => {

		event.preventDefault()

		let dataToSubmit = {
			...formData,
			invite_send_email: formData.respondent_is_internal ? true : formData.invite_send_email // always send emails to internal users
		}

		setLoading(true)
		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			if (mode === 'add') {
				//resetForm()
			} 

		})
		.catch(err => {
		})
		.finally(res => setLoading(false))
		    
	}

  const cancelForm = () => {
  	navigate(-1)
  }

  const resetForm = () => {
  	setFormData({
  		...initialFormState
			, user_id: loggedInUser.user_id
			, respondent_is_internal: formData.respondent_is_internal ? true : false
			, questionnaire_id: formData.questionnaire_id
		}) 
  }

  const respondentIsInternalValues = [{value: true, label: 'Internal'}, {value: false, label: 'External'}]

return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>

					<div className="form-block vertical">
						<label>Respondent Type</label>
						<SelectButtons 
			        name="respondent_is_internal"
			        value={formData.respondent_is_internal}
			        options={ formatSelectOptions({options: respondentIsInternalValues, optionValue: "value", optionLabel: "label"  }) }
			        onChange={handleInputChange} 
			        disabled={loading}
			      />

		      </div>
		  	
				{ !formData.respondent_is_internal &&
					<React.Fragment>
		      <div className="form-block vertical">
			      <Input type="text" 
			      	name="respondent_name" 
			      	label="Name"
			      	value={formData.respondent_name} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			     	/>
		     	</div>

		     	<div className="form-block vertical">
			      <Input type="text" 
			      	name="respondent_email" 
			      	label="Email"
			      	value={formData.respondent_email} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			     	/>
		     	</div>
		     	</React.Fragment>
		    }

				{ formData.respondent_is_internal &&
					<React.Fragment>
			      <div className="form-block vertical">
							<label>Colleague</label>
							<Select 
		            name="respondent_user_id"
		            value={formData.respondent_user_id}
		            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip:"user_email"}) }
		            onChange={handleInputChange} 
		            disabled={loading}
		            placeholder='Select a colleague'
		          />
				    </div>
		     	</React.Fragment>
		    }

		    <div className="form-block vertical">
					<label>Questionnaire</label>
					<Select 
            name="questionnaire_id"
            value={formData.questionnaire_id}
            options={ formatSelectOptions({options: questionnaires, optionValue: "questionnaire_id", optionLabel: "questionnaire_name", tooltip:"questionnaire_title"}) }
            onChange={handleInputChange} 
            disabled={loading}
            placeholder='Select a questionnaire'
          />
		    </div>

		    { !formData.respondent_is_internal &&
			  	<div className="form-block horizontal">
			      <Checkbox 
			      	name='invite_send_email' 
			      	checked={formData.invite_send_email } 
			      	onChange={handleInputChange}
			      	label='Send Invitation Email Automatically'
			      	disabled={loading}
			      	title='If checked, an email with the invitation code and link will be automatically sent to the email you specified above'
			      />
		      </div>
		    }


	    </form>
	  </div>
  )
}

export const InviteFeedbackForm = forwardRef(_InviteFeedbackForm)


