import { 
  SkeletonLoader } from '../../components'
import { Role, subjects } from '../../helpers';
import { 
  Home,
  Contact
} from './views';

const currentUser = ({ match }) => {
    const res = subjects.currentUser.value

    if (!(res === undefined || res === null) && res.user_id.toString() === match.params.user_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.user_fullname}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

export const routesConfig = (appName, displayName) => ([
  {
    path: "/" + appName + "/:user_id",
    breadcrumb: currentUser,
    element: Contact
  },
  {
    path: "/" + appName + "/",
    breadcrumb: displayName,
    element: Home
  }
]);
