import {default as Textarea} from 'react-textarea-autosize';

export const TextareaAutosize = ({name, value, onChange, invalid, disabled, readOnly, minRows}) => {
	return (
		<div className={"TextareaAutosize" + (invalid ? " invalid" : "") + (readOnly ? " read-only" : "")}>
			{invalid && <div className="invalid-message">{invalid}</div>}
			{!readOnly 
				? <Textarea 
						name={name} 
						value={value} 
						onChange={onChange} 
						disabled={disabled || readOnly}
						minRows={minRows} />
				: <div className="textarea-read-only">{value ? value : "-"}</div>
			}
		</div>
	)
}