import React from 'react';
import { 
  Icon,
  SkeletonLoader } from './components'
import { Role, subjects } from './helpers';
import { 
  Home
} from './views';

export const routesConfig = [
  {
    path: "/",
    breadcrumb: "Home",
    element: Home
  }
];
