function importAll(r) {
  let apps = {};
  //let manifests = []
  /*manifests = r.keys().filter((item) => { 
  	
  	return match 
  });*/
  //console.log(manifests)
  r.keys().forEach(item => { 
  	const match = item.match(/.+\/appDefinition\.js$/g)
  	if (match) {
  		apps[item.replace('./', '').replace(/\/.+/g, '')] = r(item).appDefinition; 
  	}
  });
  return apps;
}

/*function getAppDefinitions(apps) {
	return apps.map(app => {
		import {appDefinition} from app
		return appDefinition
	})
}*/

const apps = importAll(require.context('./', true, /.\//));

export { apps }
//export const appDefinitions = getAppDefinitions(apps)
