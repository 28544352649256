import { BehaviorSubject } from 'rxjs'

export const subjects = {
  csrfToken: new BehaviorSubject(),
  currentBusinessTerm: new BehaviorSubject(),
  currentDataset: new BehaviorSubject(),
  currentDatasetGroup: new BehaviorSubject(),
  currentDatasource: new BehaviorSubject(),
  currentField: new BehaviorSubject(),
  currentFieldDescription: new BehaviorSubject(),
  currentGlossary: new BehaviorSubject(),
  currentSystem: new BehaviorSubject(),
  currentTask: new BehaviorSubject(),
  currentUser: new BehaviorSubject(),
  currentUserGroup: new BehaviorSubject(),
  isLoggedIn: new BehaviorSubject(false),
  loggedInUser: new BehaviorSubject({}),
  settings: new BehaviorSubject([]),
}
