import React from 'react';
import { Photo, SkeletonLoader } from './'

export const ObjectDetails = ({title, photo, secondaryTitle, subtitleIcon, subtitleText, type, loading, children}) => {

  const renderSubtitleIcon = (icon, index) => {
    if (!icon) { return null}
    return <div className="object-subtitle-icon" key={index}>
            { !loading 
              ? icon
              : <SkeletonLoader circle={true} width="1.2em" height="1.2em"/>
            }
            </div>
  }

  const isUser = (type === 'USER' || type === 'USER_GROUP')

  return (
  	<div className={"ObjectDetails" + (isUser ? " center" : "")}>
      
      { !isUser &&
        <div className="object-type">
          { type }
        </div>
      }

      { isUser &&
        <Photo
          type={ type }
          size='large'
          data={ photo }
          loading={ loading }
        />
      }
      

      <div className="object-title">
        <h1>{ title || <SkeletonLoader width="200px" /> }</h1>
      </div>

      {secondaryTitle &&
        <div className="object-secondary-title">
          { secondaryTitle }
        </div>
      }

      { (subtitleIcon || subtitleText) &&
        <div className="object-subtitle">
          { subtitleIcon && Array.isArray(subtitleIcon) 
            ? subtitleIcon.map((icon, index) => {return renderSubtitleIcon(icon, index)}) 
            : renderSubtitleIcon(subtitleIcon)
          }
          { subtitleText &&
            <div className="object-subtitle-text">
              { !loading 
                ? subtitleText
                : <React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp; <SkeletonLoader width="200px" /></React.Fragment>
              }
            </div>
          }
        </div>
      }

    <div className="object-content">
  		{ children }
  	</div>

  </div>
  	)
}

