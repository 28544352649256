import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { questionnaireResponseService } from '../services';
import { Button, DataTable, Select, formatSelectOptions, SkeletonLoader, Icon } from '../../../components';
import { useModalDialog, useGlobalState, useMessage, useQueryParams } from '../../../hooks';

export const Analyze = () => {

  const { queryParams, setHistory } = useQueryParams()
  const [questionnaireResponses, setQuestionnaireResponses] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [toUserOptions, setToUserOptions] = useState([]);
  const [fromUserOptions, setFromUserOptions] = useState([]);
  const [respondentTypeOptions, setRespondentTypeOptions] = useState([]);
  const [questionnaireOptions, setQuestionnaireOptions] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showToolbar, setShowToolbar] = useState(queryParams.filter.showToolbar || false)

  const [defaultFilters, setDefaultFilters] = useState(queryParams.filter || {
    user_id: {value: loggedInUser.user_id, label: loggedInUser.user_fullname},
    respondent_name: [],
    respondent_is_internal: [],
    questionnaire_name: []
  })
  const [filters, setFilters] = useState(defaultFilters)

  const { showMessage } = useMessage()
  const { hideModalDialog } = useModalDialog()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {

    fetchItems();

  }, []);

  useLayoutEffect(() => {

    applyFilters();
    setHistory({filter: {...filters, showToolbar: showToolbar}})

  }, [filters]);

  useLayoutEffect(() => {

    applyFilters();
    setHistory({filter: {...queryParams.filter, showToolbar: showToolbar}})

  }, [showToolbar]);

  const fetchItems = async () => {
    await questionnaireResponseService.getAll()
      .then(res => {

        const responses = res.questionnaire_responses.map(item => ({
          ...item,
          view_button_class_name: !item.is_viewed && item.user_id === loggedInUser.user_id && "main-button",
          view_button_tooltip: !item.is_viewed && item.user_id === loggedInUser.user_id ? "View new feedback" : "View feedback"
        }))
        setQuestionnaireResponses(responses); 

        const filteredResponses = responses.filter(item => {
          return filters.user_id.value === item.user_id
        })  
        const fromUserOptions = getFromUserOptions(filteredResponses)
        const respondentTypeOptions = getRespondentTypeOptions(filteredResponses)
        const questionnaireOptions = getQuestionnaireOptions(filteredResponses)

        setToUserOptions( getToUserOptions(responses) )
        setFromUserOptions( fromUserOptions )
        setRespondentTypeOptions( respondentTypeOptions )
        setQuestionnaireOptions( questionnaireOptions )

        setFilters(prev => { return {
          ...prev, 
          respondent_name: fromUserOptions, 
          respondent_is_internal: respondentTypeOptions,
          questionnaire_name: questionnaireOptions
        }})
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
      })
      .finally(res => setLoading(false))
  }


  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleInputChange = (event) => {
    let { name, value } = ""

    // Handle standard form inputs
    if (event.target !== undefined) {
      name = event.target.name
      value = event.target.value

      // If input element is a checkbox, we cannot use "value"
      if (event.target.type === "checkbox") { value = event.target.checked }

    // Handle custom form inputs
    } else {
      name = event.name
      value = event.value
    }
  
    if (Array('respondent_name', 'respondent_is_internal', 'questionnaire_name').includes(name)) {
      const existingFilter = filters[name].find(x => x.value === value)
      switch (name) {
        case 'respondent_name':
          if (existingFilter) { // De-select an option
            if (value === 'all') { // Select the "all" option
              value = []
            } else {
              value = [...filters[name].filter(x => x.value !== value && x.value !== 'all')]
            }
          } else { // Select an option
            if (value === 'all' || filters[name].length === fromUserOptions.length-2) { // Select the "all" option or all other options
              value = fromUserOptions
            } else {
              value = [...filters[name], ...[{value: value}]]
            }
          }
          break
        case 'respondent_is_internal':
          if (existingFilter) {
            if (value === 'all') {
              value = []
            } else {
              value = [...filters[name].filter(x => x.value !== value && x.value !== 'all')]
            }
          } else {
            if (value === 'all' || filters[name].length === respondentTypeOptions.length-2) {
              value = respondentTypeOptions
            } else {
              value = [...filters[name], ...[{value: value}]]
            }
          }
          break
        case 'questionnaire_name':
          if (existingFilter) {
            if (value === 'all') {
              value = []
            } else {
              value = [...filters[name].filter(x => x.value !== value && x.value !== 'all')]
            }
          } else {
            if (value === 'all' || filters[name].length === questionnaireOptions.length-2) {
              value = questionnaireOptions
            } else {
              value = [...filters[name], ...[{value: value}]]
            }
          }
          break
        default:
          break
      }
      setFilters(prev => {return {...prev, [name]: value}})

    } else {
      const fromUserOptions = getFromUserOptions(
        questionnaireResponses.filter(item => {
          return item.user_id === value
        })
      )
      const respondentTypeOptions = getRespondentTypeOptions(
        questionnaireResponses.filter(item => {
          return item.user_id === value
        })
      )
      const questionnaireOptions = getQuestionnaireOptions(
        questionnaireResponses.filter(item => {
          return item.user_id === value
        })
      )

      setFromUserOptions(fromUserOptions)
      setRespondentTypeOptions(respondentTypeOptions)
      setQuestionnaireOptions(questionnaireOptions)

      setFilters({
        [name]: {value: value, label: event.option.label},
        respondent_name: fromUserOptions.map(item => { 
            return {value: item.value} 
          }),
        respondent_is_internal: respondentTypeOptions.map(item => { 
            return {value: item.value} 
          }),
        questionnaire_name: questionnaireOptions.map(item => { 
            return {value: item.value} 
          })
      })
    }
  }

  const applyFilters = () => {
    let items = [...questionnaireResponses]

    const filtered = items.filter(item => {
      return filters.user_id.value === item.user_id &&
      filters.respondent_name.findIndex(x => x.value === item.respondent_email) > -1 &&
      filters.respondent_is_internal.findIndex(x => x.value === (item.respondent_is_internal ? "true" : "false")) > -1 &&
      filters.questionnaire_name.findIndex(x => x.value === item.questionnaire_id) > -1
    })

    setFilteredItems(filtered)

  }

  const viewSelected = () => {
    const ids = selectedItems.map(item => "id=" + item.questionnaire_response_id).join(";")
    navigate(location.pathname+"/result?"+ids)
  }

  const resetFilters = () => {

    const fromUserOptions = getFromUserOptions(
      questionnaireResponses.filter(item => {
        return item.user_id === loggedInUser.user_id
      })
    )
    const respondentTypeOptions = getRespondentTypeOptions(
      questionnaireResponses.filter(item => {
        return item.user_id === loggedInUser.user_id
      })
    )

    const questionnaireOptions = getQuestionnaireOptions(
      questionnaireResponses.filter(item => {
        return item.user_id === loggedInUser.user_id
      })
    )

    setFromUserOptions(fromUserOptions)
    setRespondentTypeOptions(respondentTypeOptions)
    setQuestionnaireOptions(questionnaireOptions)

    setFilters({
      user_id: {value: loggedInUser.user_id, label: loggedInUser.user_fullname},
      respondent_name: fromUserOptions,
      respondent_is_internal: respondentTypeOptions,
      questionnaire_name: questionnaireOptions
    })
  }

  const getToUserOptions = (data) => {
    let array = [...data, {user_id: loggedInUser.user_id, user_fullname: "You"}]
    
    let uniqueArray = [...new Map(array.map(item => [item.user_id, item])).values()]

    let sortedUniqueArray = uniqueArray.sort( (a, b) => {
      var keyA = a.user_fullname,
          keyB = b.user_fullname;
        // Compare the 2 values
        if (keyB == 'You') return 1 // Always put You first
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
    })
    const options = formatSelectOptions({
      options: sortedUniqueArray, 
      optionValue: "user_id", 
      optionLabel: "user_fullname" 
    })

    return options
  }

  const getFromUserOptions = (data) => {
    let array = [
      ...data, 
      {respondent_email: "all", respondent_name: "(All)"}
      ]

    let uniqueArray = [...new Map(array.map(item => {
      return item.respondent_email === loggedInUser.user_email ? [item.respondent_email, {...item, ...{respondent_name: "You"}}] : [item.respondent_email, item]}
      )).values()]

    let sortedUniqueArray = uniqueArray.sort( (a, b) => {
      var keyA = a.respondent_name,
          keyB = b.respondent_name;
        // Compare the 2 values
        if (keyB == 'You' || keyA == '(All)') return 1 // Always put You first
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
    })
    const options = formatSelectOptions({
      options: sortedUniqueArray, 
      optionValue: "respondent_email", 
      optionLabel: "respondent_name" 
    })

    return options
  }

  const getRespondentTypeOptions = (data) => {

    let array = [
      ...data, 
      {respondent_is_internal: "all"}
    ]
    
    let uniqueArray = [...new Map(array.map(item => [item.respondent_is_internal, item])).values()]

    let sortedUniqueArray = uniqueArray.sort( (a, b) => {
      var keyA = a.respondent_is_internal,
          keyB = b.respondent_is_internal;
        // Compare the 2 values
        if (keyB == 'all') return 1 // Always put all first
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
    })

    let sortedFormattedUniqueArray = sortedUniqueArray.map(item => { 
      
      switch (item.respondent_is_internal) {
        case 'all':
          return {value: item.respondent_is_internal, label: '(All)'}
        case true:
          return {value: "true", label: 'Internal'}
        case false:
          return {value: "false", label: 'External'}
        default:
          break
      }
    })

    return sortedFormattedUniqueArray
  }

  const getQuestionnaireOptions = (data) => {
    let array = [
      ...data, 
      {questionnaire_id: "all", questionnaire_name: "(All)"}
      ]

    let uniqueArray = [...new Map(array.map(item => [item.questionnaire_id, item])).values()]

    let sortedUniqueArray = uniqueArray.sort( (a, b) => {
      var keyA = a.questionnaire_name,
          keyB = b.questionnaire_name;
        // Compare the 2 values
        if (keyB == 'all') return 1 // Always put all first
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
    })
    const options = formatSelectOptions({
      options: sortedUniqueArray, 
      optionValue: "questionnaire_id", 
      optionLabel: "questionnaire_name" 
    })

    return options
  }

  const respondentIsInternalValues = {true: {label: 'Internal'}, false: {label: 'External'}}
  const viewedValues = {true: {label: ''}, false: {label: 'New', className: 'pill pill-green'}}

  const selectedItemsCount = selectedItems.length
  const itemTotalCount = filteredItems.length
  return (
    <div className="Analyze wide-margin">

        <div className={"main-toolbar" + (showToolbar ? " show" : "")}>
          <div className="main-toolbar-header">
            <div className="main-toolbar-title">Feedback To {filters.user_id.value === loggedInUser.user_id ? "You" : filters.user_id.label}</div>
            
            { selectedItemsCount > 0 && 
              <div className="selected-items-options main-toolbar-item">
                
                <div className="main-toolbar-item main-toolbar-text">
                  { ((selectedItemsCount === itemTotalCount) ? 'All' : selectedItemsCount)} {((selectedItemsCount > 1 || selectedItemsCount === itemTotalCount) ? 'items' : 'item')} selected
                </div>        

                <Button 
                  value="View"
                  className="main-toolbar-item button" 
                  onClick={ viewSelected }
                />

              </div>
            }


            <div className="filter-container">
              { itemTotalCount !== questionnaireResponses.length &&
                <div className="main-toolbar-text">{`Showing ${itemTotalCount} of ${questionnaireResponses.length} items`}</div>
              }
              <div className="main-toolbar-filter-toggle-button" onClick={() => setShowToolbar(prev => !prev)}><Icon name="filter"/></div>
            </div>
          </div>
          <div className="main-toolbar-filters">
            { loading || (!toUserOptions || !fromUserOptions || !respondentTypeOptions)
              ? <React.Fragment><SkeletonLoader /><SkeletonLoader /><SkeletonLoader /></React.Fragment>
              : <React.Fragment>
                  <Select className="main-toolbar-item" showMultiLabel={true} label="To" value={filters.user_id.value} options={toUserOptions} name="user_id" onChange={handleInputChange} />
                  <Select className="main-toolbar-item" showMultiLabel={true} label="From" value={filters.respondent_name} options={fromUserOptions} name="respondent_name" onChange={handleInputChange} isMulti={true} />
                  <Select className="main-toolbar-item" showMultiLabel={true} label="Respondent Type" value={filters.respondent_is_internal} options={respondentTypeOptions} name="respondent_is_internal" onChange={handleInputChange} isMulti={true} />
                  <Select className="main-toolbar-item" showMultiLabel={true} label="Questionnaire" value={filters.questionnaire_name} options={questionnaireOptions} name="questionnaire_name" onChange={handleInputChange} isMulti={true} />
                  <Button className="button main-toolbar-item" value="Reset" onClick={resetFilters}/>
                </React.Fragment>
            }
          </div>
        </div>

        <DataTable
          columns={[
            {id: 'questionnaire_response_id', name: 'Feedback', type: 'button', tooltip: 'view_button_tooltip', label: 'View', onClick: (props) => {navigate(location.pathname+"/result?id="+props)}, data: {data: 'questionnaire_response_id', className: 'view_button_class_name'}},
            {id: 'respondent_user_photo', name: '', type: 'user-photo', tooltip: 'respondent_name'},
            {id: 'respondent_name', name: 'From', tooltip: 'respondent_email'},  
            {id: 'created_timestamp', name: 'Received At', type: 'datetime'},
            {id: 'respondent_is_internal', name: 'Respondent Type', type: 'boolean', values: respondentIsInternalValues, className:"prio-2"},  
            {id: 'questionnaire_name', name: 'Questionnaire', className:"prio-2"}
          ]}
          data={filteredItems}
          title="Received Feedback"
          hideToolbar={true}
          idColumn='questionnaire_response_id'
          primaryColumn='user_fullname'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          filterable={true}
          filterObjects={['user_fullname', 'respondent_name','respondent_email', 'respondent_is_internal']}
          filterObjectsValueMap={{respondent_is_internal: respondentIsInternalValues}}
        />

      </div>
  );
}