import React from 'react'
import { App } from './views'
import { AppIcon } from './components';
import { routesConfig } from './routesConfig'
import { subjects } from '../../helpers'

const displayName = "Profile"
const name = displayName.toLowerCase().replace(' ', '_')

const getNotificationCount = () => {
	let count = 0
	let loggedInUser
	const subject = subjects.loggedInUser.subscribe(s => {
		if (s && Object.keys(s).length > 0) { loggedInUser = s }
	})

	subject.unsubscribe()

	if (loggedInUser) {
		if (!loggedInUser.user_phone) { count ++}
		if (!loggedInUser.user_photo) { count ++}
		if (!loggedInUser.user_email) { count ++}
		if (!loggedInUser.user_linkedin_vanity_name) { count ++}
	}

	return {total: count}
}

export const appDefinition = {
	displayName: displayName,
	name: name,
	Component: () => <App appName={name}/>,
	AppIcon: AppIcon, 
	notificationCount: getNotificationCount,
	routes: routesConfig(name, displayName)
}