import React, {forwardRef} from 'react';
import './ToggleSwitch.css'

const _ToggleSwitch = ({value, onChange, className, label}, ref) => {
  
  const handleChange = (e) => {
    onChange && onChange(e)
  }

  return(
    <div className={"ToggleSwitch " + className} ref={ref} >
      <div className="toggle-switch-label">{label}</div>
      <label className="toggle-switch-button">
        <input type="checkbox" checked={value || false} onChange={handleChange} />
        <span className="toggle-switch-slider"></span>
      </label>  
    </div>   
  )
}

export const ToggleSwitch = forwardRef(_ToggleSwitch)