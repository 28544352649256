import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMessage, useNotification } from '../../../hooks';
import { Button } from '../../../components';
import { IconFeedback } from '../components'
import { GiveFeedbackForm } from '../forms';
import { questionnaireResponseService } from '../services'

export const Give = () => {

  const [time, setTime] = useState(3)
  const [startTimer, setStartTimer] = useState(false)
	const [sent, setSent] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const { showMessage } = useMessage()
  const [searchParams, func]  = useSearchParams()
  const navigate = useNavigate() 
  const { updateNotifications } = useNotification()  

  useEffect(() => {

    // Redirect to start page automatically when timer reached 0
    if (time === 0) { 
      goBack()
    }  
  }, [time]);

  useEffect(() => {

    // Start timer for auto redirect
    if (startTimer) {
      setInterval(() => {setTime(prev => prev-1)}, 1000);
    }
  }, [startTimer]);

  const goBack = () => {
    navigate(-1)
  }

  const addFeedback = async (data) => {
    
    await questionnaireResponseService.create(data)
    	.then( res => {
    		setSent(true)
				setStartTimer(true)
        if (data.questionnaire_response.invite_id) {
          updateNotifications()
        }
    	})
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  return (
    <div className="Give columns">
			<div className="center-column column">
        { notFound ? 
          <div className="questionnaire-not-found">
            <div className="questionnaire-not-found-icon"><IconFeedback name="not_found"/></div>
            <div className="questionnaire-not-found-text">The provided invitation code is not valid</div>
            <Button value="Close" className="button" onClick={goBack} />
          </div>

          :  <React.Fragment>
    		  	{ sent ?  
    		  		<div className="questionnaire-sent">
    		  			<div className="questionnaire-sent-icon"><IconFeedback name="give_feedback"/></div>
    		  			<div className="questionnaire-sent-text">Feedback sent!</div>
    		  			<div className="questionnaire-sent-smalltext">Closing in {time} seconds...</div>
    		  			<Button value="Close" className="button" onClick={goBack} />
    		  		</div>
    		  
    		  	:  <GiveFeedbackForm mode="add" data={Object.fromEntries(searchParams)} setNotFound={setNotFound} submitForm={addFeedback} />
    	      }
            </React.Fragment>
        }
      </div>
    </div>
  );
}