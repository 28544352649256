import React, { forwardRef } from 'react';
import { keyCode } from '../helpers';

const _Tab = ({activeTab, label, tabid, resultCount, onClick, disableTab}, ref) => {

  const onClickTabItem = () => {
    if (!disableTab) {
      onClick(tabid)
    }
  }
  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        onClickTabItem();
        break;

      default:
        break;
    }
  }

  const active = ((activeTab === tabid) ? "active" : "")

  return (
      <div 
        className={"tab-list-item " + active + (resultCount>0 ? " has-results" : "") + (disableTab ? " disabled" : "") }
        role="tab"
        ref={ref}
        aria-selected={active === "active"}
        tabIndex= {0}
        onClick={onClickTabItem} 
        onKeyDown={handleKeyDown}
      >
      <span className="label">
        { label }
      </span>
      
        { resultCount > 0 && 
          <span className="label-result-count">
          { resultCount }
          </span>
        }
      
      </div>      
  )
};
export const Tab = forwardRef(_Tab);
