import React from 'react';
import { Outlet } from 'react-router-dom'
import { Role } from '../../../helpers';
import { Icon, NavBar } from '../../../components';
import { IconFeedback } from '../components';
import { inviteService, questionnaireResponseService } from '../services';
import '../index.css';

const getUnansweredInviteCount = async () => {

  const data = await inviteService.getUnansweredInviteCount()

  return parseInt(data.unanswered_invite_count)
}

const getNewFeedbackCount = async () => {

  const data = await questionnaireResponseService.getNewFeedbackCount()

  return parseInt(data.new_feedback_count)
}

export const App = ({appName}) => {
  
  const links =[
    {
      type: "link",
      relativePath: "",
      label: "Home",
      icon: <Icon name="home" />,
      roles: [Role.admin]
    },
    {
      type: "section",
      label: "Actions",
      roles: [Role.admin],
      links: [
        {
          type: "link",
          relativePath: "invite",
          label: "Invite",
          icon: <IconFeedback name="invite_feedback" />,
          roles: [Role.admin]
        },
        {
          type: "link",
          relativePath: "requests",
          label: "Give",
          icon: <IconFeedback name="give_feedback" />,
          roles: [Role.admin],
          notificationCount: getUnansweredInviteCount
        },
        {
          type: "link",
          relativePath: "analyze",
          label: "Analyze",
          icon: <IconFeedback name="analyze_feedback" />,
          roles: [Role.admin],
          notificationCount: getNewFeedbackCount
        }
      ]
    }
  ]

  return (
    <div className={"app-container" + (appName ? " "+appName : "")}>

        <NavBar links={links} appName={appName} />

        <div className="app-content">
          <Outlet context={appName} />
        </div>

    </div>
  );
}