import React from 'react';
import { 
  Icon,
  SkeletonLoader } from '../../components'
import { Role, subjects } from '../../helpers';
import { 
  App,
  Home,
  Settings,
  Task,
  Tasks,
  User,
  Users,
  UserGroup,
  UserGroups 
} from './views';

const currentTask = ({ match }) => {
    const res = subjects.currentTask.value

    if (!(res === undefined || res === null) && res.task_id.toString() === match.params.task_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.task_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentUser = ({ match }) => {
    const res = subjects.currentUser.value

    if (!(res === undefined || res === null) && res.user_id.toString() === match.params.user_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.user_fullname}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentUserGroup = ({ match }) => {

  const res = subjects.currentUserGroup.value

    if (!(res === undefined || res === null) && res.user_group_id.toString() === match.params.user_group_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.user_group_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}


export const routesConfig = (appName, displayName) => ([
    {
    path: "/" + appName + "/tasks/:task_id",
    breadcrumb: currentTask,
    element: Task,
    roles: [Role.admin]
  },
  {
    path: "/" + appName + "/tasks",
    breadcrumb: "Tasks",
    element: Tasks,
    roles: [Role.admin]
  },
  {
    path: "/" + appName + "/users/:user_id",
    breadcrumb: currentUser,
    element: User
  },
  {
    path: "/" + appName + "/users",
    breadcrumb: "Users",
    element: Users,
    roles: [Role.admin]
  },
  {
    path: "/" + appName + "/user_groups/:user_group_id",
    breadcrumb: currentUserGroup,
    element: UserGroup,
    roles: [Role.admin]
  },
  {
    path: "/" + appName + "/user_groups",
    breadcrumb: "User Groups",
    element: UserGroups,
    roles: [Role.admin]
  },
  {
    path: "/" + appName + "/settings",
    breadcrumb: "Settings",
    element: Settings
  },
  {
    path: "/" + appName + "/",
    breadcrumb: displayName,
    element: Home
  }
]);
