import React, { useState, useEffect, useRef } from 'react';
import { Link, useOutletContext } from 'react-router-dom'
import { Asset } from '../../../helpers';
import { Icon, SkeletonLoader } from '../../../components';
import { useQueryParams, useMessage } from '../../../hooks';
import { userGroupService, taskService } from '../services';
import { userService } from '../../../services';

export const Home = () => {

  const appName = useOutletContext()

  const { queryParams, setHistory } = useQueryParams()
  const { showMessage } = useMessage()


  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  
  const [userCount, setUserCount] = useState()
  const [userGroupCount, setUserGroupCount] = useState()
  const [taskCount, setTaskCount] = useState()

  useEffect(() => {
    
    fetchUsers()
    fetchUserGroups()
    fetchTasks()

  }, []);

  const fetchUsers = async () => {
    
    userService.getAll()
      .then(res => {

        setUserCount(res.users.length);
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')})
      .finally(setLoadingUsers(false))
  }
  const fetchUserGroups = async () => {
    
    userGroupService.getAll()
      .then(res => {

        setUserGroupCount(res.user_groups.length);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')})
      .finally(setLoadingUserGroups(false))
  }
  const fetchTasks = async () => {
    
    taskService.getAll()
      .then(res => {

        setTaskCount(res.tasks.length);
         
      })
      .catch(err => {showMessage(JSON.stringify(err), 'error')})
      .finally(setLoadingTasks(false))
  }

  return (
    <div className={"Home"}>

        <div className="large-nav-links">

          <div className="large-nav-links-title">ADMIN TOOLS</div>

          <div className="large-nav-link-container">

            <Link to={"/" + appName + "/users"} className="large-nav-link">
              <div className="large-nav-link-icon-container">
                <div className="large-nav-link-icon">
                  <Icon name="users"/>
                </div>
                <div className="large-nav-link-subtitle">
                  Users
                </div>
              </div>
              <div className="large-nav-link-number">
                { loadingUsers ? <SkeletonLoader width={30} /> : userCount}
              </div>
            </Link>

            <Link to={"/" + appName + "/user_groups"} className="large-nav-link">
              <div className="large-nav-link-icon-container">
                <div className="large-nav-link-icon">
                  <Icon name="user_groups"/>
                </div>
                <div className="large-nav-link-subtitle">
                  User Groups
                </div>
              </div>
              <div className="large-nav-link-number">
                { loadingUserGroups ? <SkeletonLoader width={30} /> : userGroupCount}
              </div>
            </Link>

            <Link to={"/" + appName + "/tasks"} className="large-nav-link">
              <div className="large-nav-link-icon-container">
                <div className="large-nav-link-icon">
                  <Icon name="tasks"/>
                </div>
                <div className="large-nav-link-subtitle">
                  Tasks
                </div>
              </div>
              <div className="large-nav-link-number">
                { loadingTasks ? <SkeletonLoader width={30} /> : taskCount}
              </div>
            </Link>

          </div>
        </div>


    </div>
  );
}