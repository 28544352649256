import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { feedbackService } from '../services';
import { userService } from '../../../services';
import { Checkbox, formatSelectOptions, Input, Select, Button } from '../../../components';
import { authMethod, getBatchEditValue, formatData } from '../../../helpers'
import { useGlobalState, useDebounce, useMessage, useModalDialog, useNotification } from '../../../hooks'
import { QuestionnaireReadOnly, IconFeedback } from '../components'
import { questionService, questionnaireService, questionnaireResponseService, responseService } from '../services'


export const Result = () => { 

  const [loading, setLoading] = useState(true)

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [questionnaireResponses, setQuestionnaireResponses] = useState();
  const [questionnaire, setQuestionnaire] = useState([])
  const [questions, setQuestions] = useState([])
  const [responses, setResponses] = useState([]);

  const [searchParams] = useSearchParams()
  const questionnaireResponseIds = Object.fromEntries(searchParams).id.split(";id=").map(item => parseInt(item))

  const questionnaire_response_id = questionnaireResponseIds[0]

  const { showMessage } = useMessage()
  const { updateNotifications } = useNotification()
  const navigate = useNavigate()

  useEffect( () => {

      fetchQuestionnaireResponses()

  }, []);


  useEffect( () => {
    // Get Items
    if (questionnaireResponses) {
      fetchItems()
    }
  }, [questionnaireResponses]);

  const fetchQuestionnaireResponses = async () => {
    
    try {

      const res = await questionnaireResponseService.getByIds({questionnaire_response_ids: questionnaireResponseIds})
      setQuestionnaireResponses(res.questionnaire_responses)
      updateNotifications()

    } catch (err) {
      showMessage(err, 'error')
    };

  }

  const fetchItems = async () => {
    setLoading(true)

    const promises = [
      fetchQuestionnaire(), 
      fetchQuestions(),
      fetchResponses()
    ]

    await Promise.all(promises).then(data => {
      setQuestionnaire(data[0])
      setQuestions(data[1])
      setResponses(data[2])
    })
    .catch(err => showMessage(err, 'error'))

    setLoading(false)
  }

  const fetchQuestionnaire = () => {
    return new Promise( async (resolve, reject) => {
      try {

        const res = await questionnaireService.getById(questionnaireResponses[0].questionnaire_id)
        resolve(res.questionnaires[0])

      } catch (err) {
        reject(err)
      };
    })
  }

  const fetchQuestions = () => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await questionService.getByQuestionnaireId(questionnaireResponses[0].questionnaire_id)

        resolve(res.questions)

      } catch (err) {
        reject(err)
      };
    })
  }

  const fetchResponses = () => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await responseService.getByQuestionnaireResponseIds({questionnaire_response_ids: questionnaireResponses.map(item => item.questionnaire_response_id)})

        let result = {}

        res.responses.forEach(response => {

          result[response.question_id] = [
            ...(result[response.question_id] || []),
            {[response.questionnaire_response_id]: response.response_value}
          ]
        })

        resolve(result)

      } catch (err) {
        reject(err)
      };
    })
  }

  const cancelForm = () => {
    navigate(-1)
  }

  const formatQuestionnaireProps = () => {
    let response = []

    if (questionnaireResponses) {
      const userFullname = questionnaireResponses[0].user_id === loggedInUser.user_id ? "you" : questionnaireResponses[0].user_fullname
      response = {
        user_fullname: userFullname,
        user_first_name: questionnaireResponses[0].user_fullname.split(' ')[0]
      }
    }

    return response
  }

  return (
    <div className="Result form columns">
      <div className="center-column column">

        <QuestionnaireReadOnly 
          questionnaire={questionnaire}
          questions={questions}
          questionnaireResponses={questionnaireResponses}
          responses={responses}
          props={formatQuestionnaireProps()}
          loading={loading}
          onCancel={cancelForm}
        />
      </div>
    </div>
  )
}