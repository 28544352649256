import { useCallback, useContext, useEffect } from "react";
import { apps } from '../apps'

import { NotificationContext } from "../context/NotificationContext";

export const useNotification = () => {
  const {
    notifications,
    setNotifications
  } = useContext(NotificationContext);

  const fetchNotifications = async () => {
    let notifications = {}
    const appArray = Object.entries(apps)

    for (let i=0; i < appArray.length; i++) {
      const props = appArray[i][1]
      const appName = props.name

      let notificationCount = 0
      if (props.notificationCount) {
        notificationCount = await props.notificationCount()
      } 
      
      notifications[appName] = notificationCount
    }

    return notifications
  }

  const updateNotifications = useCallback(
    async (props) => {
      const notifications = await fetchNotifications()
      .catch(err => {console.log(err)})
      setNotifications(notifications)
    },
    [setNotifications]
  );

  return {
    notifications,
    updateNotifications
  };
}